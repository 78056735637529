import cn from 'classnames';
import { FC, ReactNode } from 'react';

type ButtonVariant = 'text' | 'icon' | 'link';
type ButtonType = 'button' | 'submit' | 'reset';
type ButtonColor =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'link'
  | 'danger'
  | 'dangerLight';
type ButtonSize = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

type Props = {
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  onClick?: () => unknown;
  fullWidth?: boolean;
  type?: ButtonType;
  isLoading?: boolean;
  disabled?: boolean;
  children: ReactNode;
  className?: string;
  title?: string;
};

export const Button: FC<Props> = ({
  variant = 'text',
  color = 'primary',
  size = 'md',
  onClick,
  fullWidth = false,
  type = 'button',
  isLoading = false,
  disabled = false,
  children,
  className,
  title,
}) => {
  const style = cn(
    'inline-flex items-center justify-center h-fit border-solid border rounded-lg gap-2 font-medium',
    { 'border-transparent': color === 'primary' }
  );

  const stylesColor: { [key in ButtonColor]: string } = {
    primary:
      'text-white bg-primary-600 border-primary-600 shadow-xs hover:bg-primary-700 hover:border-primary-700 focus:shadow-xs-primary disabled:bg-primary-200 disabled:border-primary-200',
    secondary:
      'text-gray-700 bg-white border-gray-300 hover:bg-gray-50 hover:border-gray-300 focus:shadow-xs-secondary disabled:bg-white disabled:border-gray-200 disabled:text-gray-300',
    tertiary:
      'text-gray-500 bg-transparent hover:bg-gray-50 hover:text-gray-600 disabled:text-gray-300',
    link: 'text-primary-700 bg-transparent border-none',
    danger:
      'text-white bg-error-600 border-error-600 hover:bg-error-700 hover:text-slate-100',
    dangerLight:
      'text-error-700 bg-error-50 border-error-50 hover:bg-error-100',
  };

  const stylesSizes: {
    [key in ButtonSize]: { [key in ButtonVariant]: string };
  } = {
    sm: { icon: 'p-2', text: 'py-2 px-3.5 text-sm', link: 'py-2 text-sm' },
    md: { icon: 'p-2.5', text: 'py-2.5 px-4 text-sm', link: 'py-2.5 text-sm' },
    lg: { icon: 'p-3', text: 'py-2.5 px-4 text-md', link: 'py-2.5 text-md' },
    xl: { icon: 'p-3.5', text: 'py-3 px-5 text-md', link: 'py-3 text-md' },
    '2xl': { icon: 'p-4.5', text: 'py-4 px-7 text-lg', link: 'py-4 text-lg' },
  };

  return (
    <button
      type={type}
      disabled={disabled || isLoading}
      className={cn(
        style,
        stylesColor[color],
        stylesSizes[size][variant],
        {
          'pointer-events-none': disabled,
          'w-full': fullWidth,
          relative: isLoading,
        },
        className
      )}
      title={title}
      onClick={onClick}
    >
      {isLoading && (
        <div
          className={cn(
            'absolute -left-0.25 -top-0.25 -bottom-0.25 -right-0.25 flex items-center justify-center rounded-lg',
            {
              'bg-primary-100': color === 'primary',
              'bg-white border border-gray-300': color === 'secondary',
              'bg-gray-50': color === 'tertiary',
            }
          )}
        >
          {[1, 2, 3].map((i) => (
            <span
              key={i}
              className={cn(
                'w-2 h-2 flex m-1 rounded loading-dot',
                `loading-dot--${i}`,
                {
                  'bg-primary-800': color === 'primary',
                  'bg-gray-700': color === 'secondary',
                  'bg-gray-500': color === 'tertiary',
                }
              )}
            ></span>
          ))}
        </div>
      )}
      <span className="-my-0.25 flex items-center">{children}</span>
    </button>
  );
};
