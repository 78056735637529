import { useStore } from 'effector-react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { Button, Icon } from '../../components';
import { logoutFx } from '../../feature/auth';
import {
  SETTINGS_PROFILE_ROUTE,
  SETTINGS_PASSWORD_ROUTE,
} from '../Router/constants';

export function SettingsLayout() {
  const isLoading = useStore(logoutFx.pending);
  const path = useLocation().pathname;
  const tabStyles =
    'text-sm font-medium pb-4 px-1 mr-6 cursor-pointer mb-[-1px]';
  const activeTabStyles = 'border-b-2 border-primary-700 text-primary-700';

  const docTitle =
    'Settings | Angle | Powering Subscriptions for Local Businesses';
  if (document.title !== docTitle) document.title = docTitle;

  return (
    <div className="p-8 bg-gray-50 w-full">
      <div className="flex justify-between">
        <div>
          <h3 className="mb-2 text-3xl">Settings</h3>
          <p className="text-base font-regular text-gray-500">
            Manage your account and payment settings.{' '}
          </p>
        </div>
        <Button
          color="secondary"
          isLoading={isLoading}
          onClick={() => logoutFx()}
        >
          <Icon src="logout" className="mr-2.5" />
          Log out
        </Button>
      </div>

      <div className="flex my-8 border-b">
        <Link
          to={SETTINGS_PROFILE_ROUTE}
          className={cn(tabStyles, {
            [activeTabStyles]: path === SETTINGS_PROFILE_ROUTE,
            'text-gray-500': path !== SETTINGS_PROFILE_ROUTE,
          })}
        >
          Profile
        </Link>
        <Link
          to={SETTINGS_PASSWORD_ROUTE}
          className={cn(tabStyles, {
            [activeTabStyles]: path === SETTINGS_PASSWORD_ROUTE,
            'text-gray-500': path !== SETTINGS_PASSWORD_ROUTE,
          })}
        >
          Password
        </Link>
        {/* Not needed for 1.0 version */}
        {/* <Link
          to={SETTINGS_PAYMENTS_ROUTE}
          className={cn(tabStyles, {
            [activeTabStyles]: path === SETTINGS_PAYMENTS_ROUTE,
            'text-gray-500': path !== SETTINGS_PAYMENTS_ROUTE,
          })}
        >
          Payments
        </Link> */}
      </div>

      <Outlet />
    </div>
  );
}
