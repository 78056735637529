import { Alert } from '../../components';

export const ComponentsPage = () => {
  const onClose = () => {
    return;
  };

  return (
    <div className="p-10 flex flex-row gap-x-10">
      <div className="flex flex-col gap-y-10 w-3/4">
        <Alert
          title="Primary Alert"
          text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam."
          color="primary"
          firstHref="/"
          secondHref="/"
          onClose={onClose}
        />
        <Alert
          title="Gray Alert"
          text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam."
          color="gray"
          firstHref="/"
          secondHref="/"
          onClose={onClose}
        />
        <Alert
          title="Error Alert"
          text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam."
          color="error"
          firstHref="/"
          secondHref="/"
          onClose={onClose}
        />
        <Alert
          title="Warning Alert"
          text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam."
          color="warning"
          firstHref="/"
          secondHref="/"
          onClose={onClose}
        />
        <Alert
          title="Success Alert"
          text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam."
          color="success"
          firstHref="/"
          secondHref="/"
          onClose={onClose}
        />
      </div>
      <div className="flex flex-col gap-y-10">
        <Alert title="Primary Alert" color="primary" />
        <Alert title="Gray Alert" color="gray" />
        <Alert title="Error Alert" color="error" />
        <Alert title="Warning Alert" color="warning" />
        <Alert title="Success Alert" color="success" />
      </div>
    </div>
  );
};
