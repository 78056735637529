import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { ErrorBoundary } from '@sentry/react';

import './app/sentry/sentry';
import App from './app/app';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <ErrorBoundary fallback={<div>Error</div>}>
      <App />
    </ErrorBoundary>
  </StrictMode>
);
