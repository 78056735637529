import { createEffect, createStore } from 'effector';
import {
  IUpdateUserProfileRequest,
  IGetUserProfileResponse,
  IChangeUserPasswordRequest,
} from '@angle/shared/api-types/users';
import { API_URL } from '../../config';
import { logoutFx, setIsLoggedIn } from '../auth';

export const profileUpdateFx = createEffect(
  async (data: IUpdateUserProfileRequest) => {
    const res = await fetch(`${API_URL}/users/me`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (res.status !== 200) {
      const json = await res.json();
      throw new Error(json.message);
    }
  }
);

export const getUserDataFx = createEffect(async () => {
  const res = await fetch(`${API_URL}/users/me`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  const json = await res.json();

  if (res.status === 401) {
    setIsLoggedIn();
  }

  if (!res.ok) {
    throw new Error(json.message);
  }

  const data: IGetUserProfileResponse = json;

  return data;
});

export const passwordChangeFx = createEffect(
  async (data: IChangeUserPasswordRequest) => {
    const res = await fetch(`${API_URL}/users/me/change-password`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (res.status !== 200) {
      const json = await res.json();
      throw new Error(json.message);
    } else {
      return res.status;
    }
  }
);

export const $changePasswordError = createStore('')
  .on(passwordChangeFx.failData, (_, payload) => payload.message)
  .on(passwordChangeFx.pending, () => '');

type UserData = IUpdateUserProfileRequest & {
  isInitialized: boolean;
};

const defaultUserData = {
  firstName: '',
  lastName: '',
  name: '',
  subdomain: '',
  website: '',
  isInitialized: false,
};

export const $userData = createStore<UserData>(defaultUserData)
  .on(getUserDataFx.doneData, (_, data) => {
    return {
      firstName: data.firstName === null ? '' : data.firstName,
      lastName: data.lastName === null ? '' : data.lastName,
      name: data.organization.name,
      subdomain:
        data.organization.subdomain === null ? '' : data.organization.subdomain,
      website:
        data.organization.website === null ? '' : data.organization.website,
      isInitialized: true,
    };
  })
  .on(profileUpdateFx.done, (_, payload) => {
    return {
      firstName: payload.params.firstName,
      lastName: payload.params.lastName,
      name: payload.params.name,
      subdomain: payload.params.subdomain,
      website: payload.params.website,
      isInitialized: true,
    };
  })
  .on(logoutFx.done, (_, payload) => {
    return defaultUserData;
  });

declare global {
  interface Window {
    customerly: {
      update: (object: any) => void;
      logout: () => void;
    };
  }
}

const updateCustomerly = ({
  user_id,
  name,
  email,
}: {
  user_id?: string | '';
  name: string | '';
  email?: string | '';
}) => {
  window.customerly.update({
    user_id: user_id,
    name: name,
    email: email,
    attributes: {
      user_type: 'pro',
      phone_number: '+35383940993',
      billing_address: 'Ground Floor, 71 Lower Baggot Street, Dublin, D02 P593',
    },
  });
};

getUserDataFx.doneData.watch((result) => {
  if (window.customerly)
    updateCustomerly({
      user_id: result.id,
      name: result.firstName,
      email: result.email,
    });
});

profileUpdateFx.done.watch((payload) => {
  if (window.customerly)
    updateCustomerly({
      name: payload.params.firstName,
    });
});

logoutFx.doneData.watch(() => {
  if (window.customerly) window.customerly.logout();
});
