import { Badge, Table, SeparatorText, Icon, Tooltip } from '../../components';
import { capitalizeFirstLetter } from '../../components/ui/utils';
import { useStore } from 'effector-react';
import { $members, evts } from '../../feature/members';
import { useEffect } from 'react';
import { getUserDataFx } from '../../feature/profile';
import { IGetCustomerResponse } from '@angle/shared/api-types/customers';
import { Price } from '@angle/shared/api-types/organizations';
import cn from 'classnames';
import { format, isValid } from 'date-fns';

const Column = (
  title: string,
  value: (obj: IGetCustomerResponse) => string | JSX.Element,
  sorting?: string
) => {
  return { title, value, sorting };
};

export function MembersPage() {
  const docTitle =
    'Members | Angle | Powering Subscriptions for Local Businesses';

  if (document.title !== docTitle) document.title = docTitle;

  const orgCustomers = useStore($members.customers);
  const params = useStore($members.params);
  const loading = useStore($members.loading);
  const stat = useStore($members.stat);
  const rem = orgCustomers.count % params.pageSize;
  const pagesNum =
    (orgCustomers.count - rem) / params.pageSize + (rem > 0 ? 1 : 0);

  useEffect(() => {
    getUserDataFx().then((userData) => {
      evts.setOrganizationId(userData.organization.id);
    });
  }, []);

  const currPage = params.pageNum;

  const showPrice = ({ amount, period }: Price) =>
    `${(amount / 100).toFixed(2)}/${period}`;

  const showDate = (date?: Date) => {
    if (date) {
      const dt = new Date(date);
      if (isValid(dt)) {
        return format(dt, 'MMM dd, yyyy');
      } else {
        return `${date}`;
      }
    } else {
      return '';
    }
  };

  const tableColumns = [
    Column(
      'Name',
      ({ firstName, lastName }) => `${firstName} ${lastName}`,
      'name'
    ),
    Column('Email', ({ email }) => email, 'email'),
    Column(
      'Status',
      ({ subscription: { status } }) => {
        const color = (status: string) => {
          switch (status) {
            case 'pending':
              return 'yellow';
            case 'canceled':
              return 'red';
            default:
              return 'green';
          }
        };
        return (
          <Badge color={color(status)} text={capitalizeFirstLetter(status)}>
            <div
              className={cn('w-[6px] h-[6px] rounded-full mr-1', {
                'bg-warning-500': status === 'pending',
                'bg-error-500': status === 'canceled',
                'bg-success-500': status === 'active',
              })}
            ></div>
          </Badge>
        );
      },
      'status'
    ),
    Column('Membership Tier', ({ subscription }) => subscription.name),
    Column('Price', ({ subscription }) =>
      subscription.price ? showPrice(subscription.price) : '???'
    ),
    Column(
      'Last active',
      ({ lastVisitedAt }) => showDate(lastVisitedAt),
      'lastVisited'
    ),
  ];

  const mrr = stat['sum']
    ? `$${(stat['sum'] / 100).toFixed(2).toLocaleString()}`
    : '';

  return (
    <div className="p-8 bg-gray-50 w-full flex flex-col">
      <div className="relative mb-2 w-fit">
        <h3 className="text-3xl mr-1">Members</h3>
      </div>
      <p className="mb-8 text-sm font-regular text-gray-500">
        See all your member information in one place, including membership tier,
        price and when they last visited.{' '}
      </p>

      <div className="grid grid-cols-3 gap-x-4 mb-6">
        <div className="bg-white rounded-lg border px-4 py-4">
          <div className="flex justify-between items-center text-gray-500 font-regular text-sm mb-2">
            <div>Active Members</div>
            <Tooltip disabled={false} content="The number of active members.">
              <Icon src="info" className="text-gray-400" />
            </Tooltip>
          </div>
          <div className="flex justify-between items-center">
            <div className="text-2xl">{stat['num']}</div>
          </div>
        </div>
        <div className="bg-white rounded-lg border px-4 py-4">
          <div className="flex justify-between items-center text-gray-500 font-regular text-sm mb-2">
            <div>Monthly Recurring Revenue (MRR)</div>
            <Tooltip
              disabled={false}
              content="The total value of your monthly membership on a monthly basis."
            >
              <Icon src="info" className="text-gray-400" />
            </Tooltip>
          </div>
          <div className="flex justify-between items-center">
            <div className="text-2xl">{mrr}</div>
          </div>
        </div>
      </div>

      <SeparatorText />

      <Table
        columns={tableColumns}
        rows={orgCustomers.customers}
        wrapperStyles="grow"
        tableStyles="grid-cols-subscribers-table"
        loading={loading}
        sortingColumn={params.sortingColumn}
        sortingDir={params.sortingDir}
        pagesNum={pagesNum}
        currPage={currPage}
        hasPrev={orgCustomers.hasPrev}
        hasNext={orgCustomers.hasNext}
        onClickPage={(i) => !loading && evts.setPageNumber(i)}
        onChangeOrder={(column, dir) => evts.setOrder({ column, dir })}
      />
    </div>
  );
}
