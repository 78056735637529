import { Badge } from '../../components';

export function AnalyticsPage() {
  const docTitle =
    'Analytics | Angle | Powering Subscriptions for Local Businesses';
  if (document.title !== docTitle) document.title = docTitle;

  return (
    <div className="p-8 bg-gray-50 w-full">
      <div className="relative mb-2 w-fit">
        <h3 className="text-4xl mr-1">Analytics</h3>
        <Badge color="blue" text="Soon" className="absolute top-0 left-full" />
      </div>
      <p className="mb-8 text-sm text-gray-500">
        See your subscription analytics and track key metrics like monthly
        recurring revenue and churn.{' '}
      </p>
      <img
        src="assets/images/analytics.png"
        alt="Analytics"
        className="object-cover max-h-[734px]"
      />
    </div>
  );
}
