import { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IUpdateUserProfileRequest } from '@angle/shared/api-types/users';
import { updateSchema } from '../../validation';
import { Input, Button, Alert } from '../../components';
import { profileUpdateFx, getUserDataFx } from '../../feature/profile';

type Props = {
  redirect?: string;
};

export const Profile: FC<Props> = ({ redirect = null }) => {
  const { register, handleSubmit, formState, control, setValue } =
    useForm<IUpdateUserProfileRequest>({
      resolver: yupResolver(updateSchema),
      defaultValues: {
        firstName: '',
        lastName: '',
        subdomain: '',
        name: '',
        website: '',
      },
    });

  const subdomainPart = '.anglehq.com';

  const [userEmail, setUserEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const trimHTTP = (url: string) => url.replace(/^(https|http):\/\//, '');

  useEffect(() => {
    getUserDataFx().then((data) => {
      const formData = {
        firstName: data.firstName === null ? '' : data.firstName,
        lastName: data.lastName === null ? '' : data.lastName,
        subdomain:
          data.organization.subdomain === null
            ? ''
            : data.organization.subdomain,
        name: data.organization.name,
        website:
          data.organization.website === null
            ? ''
            : trimHTTP(data.organization.website),
      };
      setUserEmail(data.email);

      Object.entries(formData).forEach(([key, value]: any) =>
        setValue(key, value)
      );
    });
  }, [setValue]);

  const onSubmit = (data: IUpdateUserProfileRequest) => {
    if (
      !data.website.startsWith('https://') &&
      !data.website.startsWith('http://')
    )
      data.website = 'https://' + data.website;

    setIsLoading(true);
    profileUpdateFx(data)
      .then(() => {
        if (redirect) navigate(redirect);
        setSuccess(true);
        setIsLoading(false);
      })
      .catch(() => {
        setSuccess(false);
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-[480px]">
      <div className="grid gap-5">
        {success && <Alert title="Your profile data successfully changed" />}
        <Input
          type="email"
          labelText="Email"
          placeholder={userEmail}
          disabled
          isMandatory
        />
        <div className="flex">
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Input
                {...register('firstName')}
                type="text"
                value={value}
                onChange={onChange}
                labelText="First Name"
                isError={!!formState.errors.firstName}
                messageText={formState.errors.firstName?.message}
                containerStyle="w-2/4 mr-5"
                isMandatory
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Input
                {...register('lastName')}
                type="text"
                value={value}
                onChange={onChange}
                labelText="Last Name"
                isError={!!formState.errors.lastName}
                messageText={formState.errors.lastName?.message}
                containerStyle="w-2/4"
                isMandatory
              />
            )}
          />
        </div>
        <Controller
          name="subdomain"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Input
              {...register('subdomain')}
              type="text"
              value={value}
              onChange={onChange}
              labelText="Subdomain"
              isError={!!formState.errors.subdomain}
              messageText={formState.errors.subdomain?.message}
              elementsRight={
                <div className="font-regular text-gray-500">
                  {subdomainPart}
                </div>
              }
              isMandatory
            />
          )}
        />
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Input
              {...register('name')}
              type="text"
              value={value}
              onChange={onChange}
              labelText="Company Name"
              isError={!!formState.errors.name}
              messageText={formState.errors.name?.message}
              isMandatory
            />
          )}
        />

        <Controller
          name="website"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Input
              {...register('website')}
              type="text"
              value={value}
              onChange={onChange}
              labelText="Website"
              isError={!!formState.errors.website}
              messageText={formState.errors.website?.message}
              website
              isMandatory
            />
          )}
        />
        <Button
          size="lg"
          type="submit"
          className="mt-8"
          isLoading={isLoading}
          onClick={() => setSuccess(false)}
          disabled={!!Object.keys(formState.errors).length}
        >
          Save Changes
        </Button>
      </div>
    </form>
  );
};
