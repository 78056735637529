import { FC } from 'react';

type Props = {
  src: string;
  width?: number | 'auto';
  height?: number | 'auto';
  alt?: string;
  className?: string;
};
export const WebpRetinaImage: FC<Props> = ({
  src,
  width,
  height,
  alt,
  className,
}) => {
  const [imageName, imageFormat] = src.split('.');
  const imagesPath = '../../../assets/images';
  return (
    <picture>
      <source
        srcSet={`${imagesPath}/${imageName}.webp 1x, ${imagesPath}/${imageName}@x2.webp 2x`}
        type="image/webp"
      />

      <img
        srcSet={`${imagesPath}/${imageName}@x2.${imageFormat} 2x`}
        src={`${imagesPath}/${src}`}
        alt={alt}
        width={width}
        height={height}
        className={className}
      />
    </picture>
  );
};
