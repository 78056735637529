import { createEffect } from 'effector';
import { API_URL } from '../../config';

const TEST_ORG_ID = '65a44a22-c46a-4c77-966e-f5ba6ce52688';

export type StripeConnectionStatus = {
  readonly isAccountConnected: boolean;
  readonly isOnboardingCompleted: boolean;
  readonly displayName: string;
};

export const connectStripeFx = createEffect(async () => {
  const res = await fetch(
    `${API_URL}/organizations/${TEST_ORG_ID}/stripe/connect`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
  const json = await res.json();

  if (!res.ok) {
    throw new Error(json.message);
  }

  const data: { accountLink: string } = json;

  window.open(data.accountLink, '_blank');
});

export const redirectToDashboardFx = createEffect(async () => {
  const res = await fetch(
    `${API_URL}/organizations/${TEST_ORG_ID}/stripe/dashboard-link`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
  const json = await res.json();

  if (!res.ok) {
    throw new Error(json.message);
  }

  const data: { dashboardLink: string } = json;

  window.open(data.dashboardLink, '_blank');
});

export const redirectToOnboardingFx = createEffect(async () => {
  const res = await fetch(
    `${API_URL}/organizations/${TEST_ORG_ID}/stripe/onboarding-link`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
  const json = await res.json();

  if (!res.ok) {
    throw new Error(json.message);
  }

  const data: { accountLink: string } = json;

  window.open(data.accountLink, '_blank');
});

export const getStripeConnectionStatusFx = createEffect(async () => {
  const res = await fetch(
    `${API_URL}/organizations/${TEST_ORG_ID}/stripe/status`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
  const json = await res.json();

  if (!res.ok) {
    throw new Error(json.message);
  }

  const data: StripeConnectionStatus = json;

  return data;
});
