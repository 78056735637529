import cn from 'classnames';
import { FC } from 'react';
import { Button } from './Button';
import Link from 'next/link';

import infoCircleIcon from '../../../assets/icons/info-circle.svg';
import alertTriangleIcon from '../../../assets/icons/alert-triangle.svg';
import checkCircleIcon from '../../../assets/icons/check-circle.svg';
import arrowRightIcon from '../../../assets/icons/arrow-big-right.svg';
import closeIcon from '../../../assets/icons/x-close.svg';

type AlertColor = 'primary' | 'gray' | 'error' | 'warning' | 'success';

type Props = {
  color?: AlertColor;
  title: string;
  text?: string;
  firstHref?: string;
  secondHref?: string;
  onClose?: () => void;
};

// TODO: add icon colors
export const Alert: FC<Props> = ({
  color = 'primary',
  title,
  text,
  firstHref,
  secondHref,
  onClose,
}) => {
  return (
    <div
      className={cn(
        'flex flex-row items-start justify-between p-2 border rounded-lg',
        {
          'border-primary-300 bg-primary-25': color === 'primary',
          'border-gray-300 bg-gray-25': color === 'gray',
          'border-error-300 bg-error-25': color === 'error',
          'border-warning-300 bg-warning-25': color === 'warning',
          'border-success-300 bg-success-25': color === 'success',
        }
      )}
    >
      <div className="p-2 flex flex-col gap-y-3 sm:flex-row sm:gap-x-3">
        {(color === 'primary' || color === 'gray' || color === 'error') && (
          <img className="w-5 h-5" src={infoCircleIcon} alt="info-circle" />
        )}
        {color === 'warning' && (
          <img
            className="w-5 h-5"
            src={alertTriangleIcon}
            alt="alert-triangle"
          />
        )}
        {color === 'success' && (
          <img className="w-5 h-5" src={checkCircleIcon} alt="check-circle" />
        )}
        <div>
          <div
            className={cn('text-sm font-medium', {
              'text-primary-700': color === 'primary',
              'text-gray-700': color === 'gray',
              'text-error-700': color === 'error',
              'text-warning-700': color === 'warning',
              'text-success-700': color === 'success',
            })}
          >
            {title}
          </div>
          {text && (
            <div
              className={cn('text-sm font-regular mt-1', {
                'text-primary-600': color === 'primary',
                'text-gray-600': color === 'gray',
                'text-error-600': color === 'error',
                'text-warning-600': color === 'warning',
                'text-success-600': color === 'success',
              })}
            >
              {text}
            </div>
          )}
          {(firstHref || secondHref) && (
            <div className="flex gap-x-3 mt-3">
              {firstHref && (
                <Link
                  href={firstHref}
                  passHref
                  className={cn('text-sm font-medium', {
                    'text-primary-500 hover:text-primary-600 focus:text-primary-500 disabled:text-primary-300':
                      color === 'primary',
                    'text-gray-500  hover:text-gray-600 focus:text-gray-500 disabled:text-gray-300':
                      color === 'gray',
                    'text-error-600  hover:text-error-700 focus:text-error-600 disabled:text-error-300':
                      color === 'error',
                    'text-warning-600  hover:text-warning-700 focus:text-warning-600 disabled:text-warning-300':
                      color === 'warning',
                    'text-success-600  hover:text-success-700 focus:text-success-600 disabled:text-success-300':
                      color === 'success',
                  })}
                >
                  Learn more
                </Link>
              )}
              {secondHref && (
                <Link
                  href={secondHref}
                  passHref
                  className={cn('text-sm font-medium', {
                    'text-primary-700 hover:text-primary-800 focus:text-primary-700 disabled:text-primary-300':
                      color === 'primary',
                    'text-gray-700  hover:text-gray-800 focus:text-gray-700 disabled:text-gray-300':
                      color === 'gray',
                    'text-error-700  hover:text-error-800 focus:text-error-700 disabled:text-error-300':
                      color === 'error',
                    'text-warning-700  hover:text-warning-800 focus:text-warning-700 disabled:text-warning-300':
                      color === 'warning',
                    'text-success-700  hover:text-success-800 focus:text-success-700 disabled:text-success-300':
                      color === 'success',
                  })}
                >
                  <span className="mr-2">View changes</span>
                  <img
                    className="w-5 h-5 inline"
                    src={arrowRightIcon}
                    alt="arrow-right"
                  />
                </Link>
              )}
            </div>
          )}
        </div>
      </div>

      {onClose && (
        <Button
          variant="icon"
          size="sm"
          color="tertiary"
          onClick={onClose}
          className="min-w-fit"
        >
          <img src={closeIcon} alt="x-close" />
        </Button>
      )}
    </div>
  );
};
