import { FC, ReactNode } from 'react';
import Link from 'next/link';
import cn from 'classnames';

type Props = {
  href: string;
  color?: 'primary' | 'gray';
  target?: '_self' | '_blank';
  children: ReactNode;
  className: string;
};

export const NextLink: FC<Props> = ({
  href,
  color = 'primary',
  target = '_self',
  children,
  className,
}) => {
  const style = cn(
    'flex items-center text-sm font-medium disabled:text-gray-300 ',
    {
      'text-primary-700 hover:text-primary-800 focus:text-primary-700':
        color === 'primary',
      'text-gray-500 hover:text-gray-600 focus:text-gray-500': color === 'gray',
    }
  );

  return (
    <div className={className}>
      <Link href={href} passHref>
        <a className={style} target={target}>
          {children}
        </a>
      </Link>
    </div>
  );
};
