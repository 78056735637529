import { Badge, Table } from '../../components';
import { capitalizeFirstLetter } from '../../components/ui/utils';
import { useStore } from 'effector-react';
import { $orders, evts } from '../../feature/orders';
import { useEffect } from 'react';
import { getUserDataFx } from '../../feature/profile';
import { IGetOrderByOrg, OrderStatus } from '@angle/shared/api-types/orders';
import { format, isValid } from 'date-fns';

const addCol = (
  title: string,
  value: (obj: IGetOrderByOrg) => string | JSX.Element,
  sorting?: string
) => {
  return { title, value, sorting };
};

const orderStatusFix = (status: OrderStatus): string => {
  switch (status) {
    case 'open':
      return 'Submitted';
    case 'canceled':
      return 'Canceled';
    case 'completed':
      return 'Finished';
    default:
      return status;
  }
};

export function OrdersPage() {
  const docTitle =
    'Orders | Angle | Powering Subscriptions for Local Businesses';
  if (document.title !== docTitle) document.title = docTitle;

  const orgOrders = useStore($orders.customers);
  const params = useStore($orders.params);
  const loading = useStore($orders.loading);
  const rem = orgOrders.count % params.pageSize;
  const pagesNum =
    (orgOrders.count - rem) / params.pageSize + (rem > 0 ? 1 : 0);

  const currPage = params.pageNum;
  useEffect(() => {
    getUserDataFx().then((userData) => {
      evts.setOrganizationId(userData.organization.id);
    });
  }, []);

  const showPrice = (amount: number) => `$${(amount / 100).toFixed(2)}`;

  const showDate = (date?: Date) => {
    if (date) {
      const dt = new Date(date);
      if (isValid(dt)) {
        return format(dt, `MMM dd, yyyy 'at' hh:mm a`);
      } else {
        return `${date}`;
      }
    } else {
      return '';
    }
  };

  const tableColumns = [
    addCol('Customer', ({ customer: { firstName, lastName, email } }) => {
      const fullName = `${firstName} ${lastName}`;

      return (
        <>
          <p className="truncate">{fullName}</p>
          <p className="truncate text-gray-500">{email}</p>
        </>
      );
    }),
    addCol('Amount', ({ totalPrice }) => showPrice(totalPrice)),
    addCol('Status', ({ status }) => (
      <Badge
        color="light-blue"
        text={capitalizeFirstLetter(orderStatusFix(status))}
      />
    )),
    addCol('Items', ({ items }) => {
      const itemRows = items.map(({ name, quantity }) => (
        <li>
          {quantity}x {name}
        </li>
      ));
      return (
        <ul>
          <li>{items.length} items</li>
          {itemRows}
        </ul>
      );
    }),
    addCol('Location', ({ locationName }) => locationName),
    addCol('Order Date', ({ createdAt }) => showDate(createdAt), 'createdAt'),
  ];

  return (
    <div className="p-8 bg-gray-50 w-full">
      <div className="relative mb-2 w-fit">
        <h3 className="text-4xl mr-1">Orders</h3>
      </div>
      <p className="mb-8 text-sm text-gray-500">
        See every order placed by a member.{' '}
      </p>
      <Table<IGetOrderByOrg>
        columns={tableColumns}
        rows={orgOrders.orders}
        wrapperStyles="grow"
        tableStyles="grid-cols-orders-table"
        loading={loading}
        pagesNum={pagesNum}
        currPage={currPage}
        hasPrev={orgOrders.hasPrev}
        hasNext={orgOrders.hasNext}
        sortingColumn={params.sortingColumn}
        sortingDir={params.sortingDir}
        onClickPage={(i) => !loading && evts.setPageNumber(i)}
        onChangeOrder={(column, dir) => evts.setSortingParam({ column, dir })}
      />
    </div>
  );
}
