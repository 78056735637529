import { object, string } from 'yup';

const urlWithoutHTTPPattern = new RegExp(
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$',
  'i'
);

// the regex below checks for
// * a subdomain that starts with a letter or number
// * can has a dash in the middle
// * ends with a letter or number
const subdomainRegex = new RegExp('^[a-zA-Z0-9]+[a-zA-Z0-9\\-]*[a-zA-Z0-9]+$');

export const updateSchema = object().shape({
  firstName: string().required('First Name is required'),
  lastName: string().required('Last Name is required'),
  subdomain: string()
    .max(60, 'Subdomain must be less than 60 characters')
    .required('Subdomain is required')
    .matches(subdomainRegex, 'This subdomain is not valid'),
  name: string().required('Company Name is required'),
  website: string()
    .required('Website is required')
    .matches(urlWithoutHTTPPattern, {
      message: 'This website is not valid',
      excludeEmptyStrings: true,
    }),
});
