import debounce from 'debounce';
import cn from 'classnames';
import { FC, useCallback, useState } from 'react';
import { validatePassword } from '../../validation';
import { Input } from '../ui';
import { SignUpSchema } from '../../pages/SignupPage';
import { string, ValidationError } from 'yup';

type Props = {
  setIsPasswordValid: (val: boolean) => void;
  getValues: () => SignUpSchema;
  setPassword: (val: string) => void;
  password: string;
};
export const StytchPasswordInput: FC<Props> = ({
  setIsPasswordValid,
  getValues,
  setPassword,
  password,
}) => {
  const [score, setScore] = useState(0);
  const [message, setMessage] = useState('');
  const { isPasswordValid } = getValues();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onPasswordValidate = useCallback(
    debounce(async (email: string, password: string) => {
      try {
        string()
          .email('Your email is invalid')
          .required('First input email')
          .validateSync(email);
      } catch (e) {
        setMessage((e as ValidationError).message);
        setScore(0);
        setIsPasswordValid(false);
        return;
      }

      if (!password) {
        setMessage('Password is required');
        setScore(0);
        setIsPasswordValid(false);
        return;
      }

      const data = await validatePassword(email, password);

      setScore(data.score);
      setIsPasswordValid(data.validPassword);

      if (data.feedback.warning) {
        setMessage(data.feedback.warning);
        return;
      }

      if (data.breachedPassword) {
        setMessage('This password is breached. Choose another');
        return;
      }

      switch (data.score) {
        case 3:
          setMessage('Your password is strong');
          break;
        case 4:
          setMessage('Great job! This is a super strong password.');
          break;
        default:
          setMessage('Your password is weak');
          break;
      }
    }, 50),
    []
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPass = e.target.value;
    const { email } = getValues();
    onPasswordValidate(email, newPass);

    setPassword(newPass);
  };
  return (
    <div>
      <Input
        type="password"
        labelText="Password"
        value={password}
        onChange={onChange}
        isError={!isPasswordValid}
        isMandatory
      />

      <div className="flex mt-1.5 items-center gap-1.5">
        {new Array(4).fill(0).map((_, idx) => {
          return (
            <p
              className={cn('w-full h-1 bg-gray-300 rounded-sm', {
                'bg-error-500': score <= 2 && score >= idx + 1,
                'bg-warning-500': score === 3 && score >= idx + 1,
                'bg-success-500': score === 4 && score >= idx + 1,
              })}
              key={idx}
            ></p>
          );
        })}
      </div>
      <p
        className={cn('text-sm font-regular text-gray-500 mt-1.5', {
          'text-error-500': !isPasswordValid,
        })}
      >
        {message}
      </p>
    </div>
  );
};
