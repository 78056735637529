import { object, ref, string, boolean } from 'yup';
import { API_URL } from '../../config';

export const loginSchema = object().shape({
  email: string().email('Email is invalid').required('Email is required'),
  password: string()
    .min(8, 'Must be at least 8 characters.')
    .required('Password is required'),
});

export const signUpSchema = object().shape({
  email: string().email('Email is invalid').required('Email is required'),
  isPasswordValid: boolean().required().oneOf([true]),
});

export const changePasswordSchema = object().shape({
  currentPassword: string().required('Current Password cannot be blank.'),
  newPassword: string()
    .required('New Password cannot be blank.')
    .min(8, 'Password length should be at least 8 characters'),
  confirmNewPassword: string()
    .required('New Password cannot be blank.')
    .min(8, 'Password length should be at least 8 characters')
    .oneOf([ref('newPassword')], 'Retyped password does not match.'),
});

export const validatePassword = async (email: string, password: string) => {
  const res = await fetch(`${API_URL}/users/password-check`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });
  return res.json();
};
