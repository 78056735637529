import { useEffect, useState } from 'react';
import {
  connectStripeFx,
  getStripeConnectionStatusFx,
  redirectToDashboardFx,
  redirectToOnboardingFx,
} from '../../feature/stripe';
import { Button } from '../ui';

export const StripeConnect = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState('undefined');
  const [isConnecting, setIsConnecting] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [isDashboardLoading, setIsDashboardLoading] = useState(false);
  const [isAccountConnected, setAccountConnected] = useState(false);
  const [isOnboardingCompleted, setOnboardingCompleted] = useState(false);

  const getConnectingLinkFx = async () => {
    setIsConnecting(true);
    await connectStripeFx();
    setIsConnecting(false);
  };

  const getOnboardingLinkFx = async () => {
    setIsOnboarding(true);
    await redirectToOnboardingFx();
    setIsOnboarding(false);
  };

  const getDashboardLinkFx = async () => {
    setIsDashboardLoading(true);
    await redirectToDashboardFx();
    setIsDashboardLoading(false);
  };

  useEffect(() => {
    getStripeConnectionStatusFx()
      .then((data) => {
        setAccountConnected(data.isAccountConnected);
        setOnboardingCompleted(false);
        setOnboardingCompleted(data.isOnboardingCompleted);
        setStatus(JSON.stringify(data));
        setIsLoading(false);
      })
      .catch(() => {
        setStatus('error');
        setIsLoading(false);
      });
  }, []);

  if (status === 'error') return <p>Error</p>;

  console.log(status);

  if (isLoading) return <p>Loading</p>;

  return (
    <div className="my-4 mx-8">
      <h1 className="mb-4 text-2xl">Stripe Connect Test</h1>
      <h2 className="mb-4 text-xl">
        Stripe connection status:
        {isAccountConnected ? (
          <span className="text-primary-600"> Connected</span>
        ) : (
          <span className="text-warning-600"> Not connected</span>
        )}
      </h2>
      <h2 className="mb-4 text-xl">
        Stripe onboarding status:
        {isOnboardingCompleted ? (
          <span className="text-primary-600"> Onboarding Completed</span>
        ) : (
          <span className="text-warning-600"> In Progress</span>
        )}
      </h2>

      <Button
        className="mr-4"
        isLoading={isConnecting}
        disabled={isAccountConnected}
        onClick={() => getConnectingLinkFx()}
      >
        Connect with Stripe
      </Button>

      <Button
        className="mr-4"
        isLoading={isOnboarding}
        disabled={!isAccountConnected || isOnboardingCompleted}
        onClick={() => getOnboardingLinkFx()}
      >
        Continue Onboarding
      </Button>

      <Button
        isLoading={isDashboardLoading}
        disabled={!isAccountConnected || !isOnboardingCompleted}
        onClick={() => getDashboardLinkFx()}
      >
        Go to Dashboard
      </Button>
    </div>
  );
};
