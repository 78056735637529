import { useStore } from 'effector-react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Button, Input, StytchPasswordInput, NextLink } from '../../components';
import { $authError, signUpFx } from '../../feature/auth';
import { LOGIN_ROUTE } from '../Router/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { signUpSchema } from '../../validation';

import { useState } from 'react';

export type SignUpSchema = {
  email: string;
  isPasswordValid: boolean;
};

export function SignupPage() {
  const isLoading = useStore(signUpFx.pending);
  const error = useStore($authError);
  const { register, formState, handleSubmit, getValues, setValue } =
    useForm<SignUpSchema>({
      resolver: yupResolver(signUpSchema),
      defaultValues: { isPasswordValid: true },
    });

  const [password, setPassword] = useState('');

  const { isPasswordValid } = getValues();

  const onSignup = ({ email }: SignUpSchema) => {
    signUpFx({ email, password });
  };

  const docTitle =
    'Sign Up | Angle | Powering Subscriptions for Local Businesses';
  if (document.title !== docTitle) document.title = docTitle;

  return (
    <>
      <div className="mb-8">
        <h1 className="mb-3 font-semibold text-gray-900 text-display-sm text-left">
          Sign up
        </h1>
        <p className="text-gray-500 text-md font-regular text-left">
          Sign up free and start your trial.
        </p>
      </div>
      <form onSubmit={handleSubmit(onSignup)}>
        {error && <p>{error}</p>}
        <div className="grid gap-5">
          <Input
            {...register('email')}
            type="email"
            labelText="Email"
            isMandatory
            isError={!!formState.errors.email}
            messageText={formState.errors.email?.message}
            focus={true}
          />

          <input
            {...register('isPasswordValid', { value: isPasswordValid })}
            type="checkbox"
            hidden
          />

          <StytchPasswordInput
            password={password}
            setPassword={setPassword}
            setIsPasswordValid={(val) => setValue('isPasswordValid', val)}
            getValues={getValues}
          />

          <div className="text-gray-500 text-sm font-regular mb-6">
            <span>By signing up for Angle you are agreeing to our </span>
            <NextLink
              href="https://anglehq.com/terms-of-service"
              color="gray"
              target="_blank"
              className="underline inline-block"
            >
              {' '}
              Terms of Service{' '}
            </NextLink>
            <span> and </span>
            <NextLink
              href="https://anglehq.com/privacy-policy"
              color="gray"
              target="_blank"
              className="underline inline-block"
            >
              {' '}
              Privacy Policy{' '}
            </NextLink>
          </div>
        </div>
        <div className="mt-6">
          <Button
            size="lg"
            isLoading={isLoading}
            type="submit"
            fullWidth
            onClick={() => !password && setValue('isPasswordValid', false)}
          >
            Get started
          </Button>
        </div>
        <div className="mt-8">
          <p className="flex items-center justify-center gap-1 text-base font-regular text-gray-500 font-regular">
            Already have an account?
            <Link to={LOGIN_ROUTE} className="font-medium text-primary-700">
              Log in
            </Link>
          </p>
        </div>
      </form>
    </>
  );
}
