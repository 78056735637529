import { useStore } from 'effector-react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { $isLoggedIn } from '../../../feature/auth';
import { $userData } from '../../../feature/profile';
import {
  PROFILE_ROUTE,
  MAIN_ROUTE,
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
} from '../constants';

export const PublicPage = () => {
  const isLoggedIn = useStore($isLoggedIn);
  const userData = useStore($userData);

  const isPathLogin = useLocation().pathname === LOGIN_ROUTE;
  const isPathSignup = useLocation().pathname === SIGNUP_ROUTE;

  if (isLoggedIn) {
    if (userData.isInitialized || isPathLogin || isPathSignup)
      return <Navigate to={MAIN_ROUTE} replace />;
    return <Navigate to={PROFILE_ROUTE} replace />;
  }

  return <Outlet />;
};
