import { Badge } from '../../components';

export function WalletPassesPage() {
  const docTitle =
    'Wallet Passes | Angle | Powering Subscriptions for Local Businesses';
  if (document.title !== docTitle) document.title = docTitle;

  return (
    <div className="p-8 bg-gray-50 w-full">
      <div className="relative mb-2 w-fit">
        <h3 className="text-4xl mr-1">Wallet Passes</h3>
        <Badge color="blue" text="Soon" className="absolute top-0 left-full" />
      </div>
      <p className="mb-8 text-sm text-gray-500">
        Design unique wallet passes for your subscribers & members to build
        brand affinity.{' '}
      </p>
      <img
        src="assets/images/wallet-passes.png"
        alt="Wallet Passes"
        className="object-cover max-h-[734px]"
      />
    </div>
  );
}
