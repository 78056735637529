import { Badge } from '../../components';

export function LandingPagesPage() {
  const docTitle =
    'Landing Pages | Angle | Powering Subscriptions for Local Businesses';
  if (document.title !== docTitle) document.title = docTitle;

  return (
    <div className="p-8 bg-gray-50 w-full">
      <div className="relative mb-2 w-fit">
        <h3 className="text-4xl mr-1">Landing Pages</h3>
        <Badge color="blue" text="Soon" className="absolute top-0 left-full" />
      </div>
      <p className="mb-8 text-sm text-gray-500">
        Create and manage new landing pages to promote your plans, landing pages
        are always 100% branded to your business.{' '}
      </p>
      <img
        src="assets/images/landing-pages.png"
        alt="Landing Pages"
        className="object-cover max-h-[734px]"
      />
    </div>
  );
}
