export const LOGIN_ROUTE = '/login';
export const SIGNUP_ROUTE = '/signup';
export const PROFILE_ROUTE = '/profile';

export const MAIN_ROUTE = '/';

export const DASHBOARD_ROUTE = '/dashboard';
export const MEMBERS_ROUTE = '/members';
export const ORDERS_ROUTE = '/orders';
export const ANALYTICS_ROUTE = '/analytics';
export const SUBSCRIPTION_PLANS_ROUTE = '/subscription-plans';
export const CATALOG_ROUTE = '/catalog';
export const LANDING_PAGES_ROUTE = '/landing-pages';
export const WALLET_PASSES_ROUTE = '/wallet-passes';
export const INTEGRATIONS_ROUTE = '/integrations';
export const SETTINGS_PROFILE_ROUTE = '/settings/profile';
export const SETTINGS_PASSWORD_ROUTE = '/settings/password';

export const COMPONENTS_ROUTE = '/components';
