import { createEvent, createEffect, createStore } from 'effector';
import {
  IGetCustomersByOrgRequest,
  IGetCustomersResponse,
  IGetCustomerStatusesRequest,
} from '@angle/shared/api-types/customers';

import { API_URL, DEFAULT_PAGE_SIZE } from '../../config';

type SortingDirection = 'asc' | 'desc';

export type IMembersPages = {
  organizationId: string;
  pageSize: number;
  pageNum: number;
  sortingColumn: string;
  sortingDir: SortingDirection;
};

type SortingParam = {
  column: string;
  dir: SortingDirection;
};

export const evts = {
  loadPage: createEvent<IGetCustomersByOrgRequest>(),
  setOrganizationId: createEvent<string>(),
  setPageSize: createEvent<number>(),
  setPageNumber: createEvent<number>(),
  setParams: createEvent<IMembersPages>(),
  setOrder: createEvent<SortingParam>(),
  setSortingColumn: createEvent<string>(),
  setSortingDirection: createEvent<SortingDirection>(),
};

const InitialMembersPage: IMembersPages = {
  organizationId: '',
  pageSize: DEFAULT_PAGE_SIZE,
  pageNum: 0,
  sortingColumn: 'lastVisited',
  sortingDir: 'desc',
};

export const getCustomersStatFx = createEffect(
  async ({ organizationId }: IGetCustomerStatusesRequest) => {
    const res = await fetch(`${API_URL}/customers/org/${organizationId}/stat`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const json = await res.json();

    if (!res.ok) {
      throw new Error(json.message);
    }

    return json;
  }
);

export const getOrgCustomersFx = createEffect(
  async ({
    organizationId,
    pageSize,
    pageNum,
    sortingColumn,
    sortingDir,
  }: IGetCustomersByOrgRequest) => {
    const query: Record<string, string | number> = {
      pageSize,
      pageNum,
    };

    if (sortingColumn !== undefined) {
      query['orderBy'] = (sortingDir === 'desc' ? '-' : '') + sortingColumn;
    }

    const queryStr = Object.keys(query)
      .map((k) => `${k}=${query[k]}`)
      .join('&');
    const res = await fetch(
      `${API_URL}/customers/org/${organizationId}?${queryStr}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    const json = await res.json();

    if (!res.ok) {
      throw new Error(json.message);
    }

    return json;
  }
);

export const $members = {
  params: createStore<IMembersPages>(InitialMembersPage),
  customers: createStore<IGetCustomersResponse>({
    customers: [],
    pageNum: 0,
    hasNext: false,
    hasPrev: false,
    count: 0,
  }),
  stat: createStore<Record<string, number>>({ active: 0 }),
  loading: getOrgCustomersFx.pending,
};

$members.params.on(evts.setPageSize, (state, size) => {
  return { ...state, pageSize: size };
});

$members.params.on(evts.setOrganizationId, (state, id) => {
  return { ...state, organizationId: id };
});

$members.params.on(evts.setPageNumber, (state, num) => {
  return { ...state, pageNum: num };
});

$members.params.on(evts.setSortingColumn, (state, column) => {
  return { ...state, pageNum: 0, sortingColumn: column };
});

$members.params.on(evts.setSortingDirection, (state, dir) => {
  return { ...state, pageNum: 0, sortingDir: dir };
});

$members.params.on(evts.setOrder, (state, { column, dir }) => {
  return { ...state, pageNum: 0, sortingColumn: column, sortingDir: dir };
});

$members.params.on(evts.setParams, (state, newState) => {
  return { ...state, ...newState };
});

$members.params.watch(
  ({ organizationId, pageSize, pageNum, sortingColumn, sortingDir }) => {
    if (organizationId !== '') {
      getOrgCustomersFx({
        organizationId,
        pageSize,
        pageNum,
        sortingColumn,
        sortingDir,
      });
    }
  }
);

evts.setOrganizationId.watch((organizationId) =>
  getCustomersStatFx({ organizationId })
);

$members.customers.on(getOrgCustomersFx.doneData, (_, newState) => newState);
$members.stat.on(getCustomersStatFx.doneData, (_, newState) => newState);
