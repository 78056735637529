import { FC } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { MAIN_ROUTE, PROFILE_ROUTE } from '../../pages/Router/constants';

import logoIcon from '../../../assets/icons/logo.svg';
import mailIcon from '../../../assets/icons/mail.svg';

import { WebpRetinaImage } from '../ui/Image';

export const AuthLayout: FC = () => {
  const location = useLocation();
  const isProfilePage = location.pathname === PROFILE_ROUTE;

  return (
    <div className="grid h-full min-h-screen lg:grid-cols-2 text-center">
      <div className="flex flex-col">
        <header className="px-4 py-8 sm:px-8">
          <Link to={MAIN_ROUTE}>
            <img width={70} height={32} src={logoIcon} alt="Angle Logo" />
          </Link>
        </header>
        <main className="flex items-center justify-center flex-grow px-4 sm:px-0">
          <div className={cn('w-full', { 'xs:w-90': !isProfilePage })}>
            <Outlet />
          </div>
        </main>
        <footer className="flex flex-col items-center justify-between px-8 pb-8 text-sm text-gray-500 sm:flex-row pt-11 font-regular">
          <p className="mb-4 sm:mb-0">© Angle Technologies, Inc. 2022</p>
          <a
            href="mailto:hello@anglehq.com"
            className="flex items-center justify-center gap-2"
          >
            <img src={mailIcon} alt="mail-icon" />
            <span>hello@anglehq.com</span>
          </a>
        </footer>
      </div>
      <div
        className={cn(
          'flex-col overflow-hidden max-h-screen items-center justify-center hidden lg:flex',
          {
            'bg-primary-100': isProfilePage,
            'bg-[#C9E0F5]': !isProfilePage,
          }
        )}
      >
        {!isProfilePage && (
          <div className="p-20 ">
            <figure>
              <blockquote className="mb-6 font-medium text-gray-900 text-display-xs">
                By adding a subscription offering you create a legion of
                customers who interact with your company each month. Every
                touchpoint represents another opportunity for you to sell more
                to your existing customers.
              </blockquote>
              <figcaption>
                <p className="text-lg font-semibold text-gray-900">
                  John Warrillow
                </p>
                <p className="text-gray-500 font-regular text-md">
                  <cite className="not-italic">
                    Author, The Automatic Customer
                  </cite>
                </p>
              </figcaption>
            </figure>
          </div>
        )}
        <div className={cn({ 'max-h-[50%]': !isProfilePage })}>
          <WebpRetinaImage
            src={isProfilePage ? 'profile.png' : 'auth-image.png'}
            width={isProfilePage ? 'auto' : 1121}
            height={isProfilePage ? 'auto' : 802}
            alt="Modernist Coffee"
            className={cn('relative hidden mx-auto sm:inline-block', {
              'max-w-[1121px] top-[-95px] right-[-40px]': !isProfilePage,
            })}
          />
        </div>
      </div>
    </div>
  );
};
