import { Profile } from '../AccountSettingsPage/Profile';
import { MAIN_ROUTE } from '../Router/constants';

export function ProfilePage() {
  const docTitle =
    'Profile | Angle | Powering Subscriptions for Local Businesses';
  if (document.title !== docTitle) document.title = docTitle;

  return (
    <div className="p-8 w-ful flex flex-col items-center">
      <h1 className="mb-8 font-semibold text-gray-900 text-display-sm text-left w-[408px]">
        Let’s get the ball rolling!
      </h1>
      <Profile redirect={MAIN_ROUTE} />
    </div>
  );
}
