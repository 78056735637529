import { Badge, Checklist, Icon } from '../../components';

const items = [
  { id: 'item-1', text: 'Create your Angle account', done: true },
  {
    id: 'item-2',
    text: 'Add basic information and import branding',
    done: true,
  },
  { id: 'item-3', text: 'Set up your first subscription plan', done: false },
  {
    id: 'item-4',
    text: 'Connect POS or e-commerce integrations',
    done: false,
  },
  {
    id: 'item-5',
    text: 'Review your subscription program and promo plan',
    done: false,
  },
  { id: 'item-6', text: 'Go live!', done: false },
];

export function DashboardPage() {
  const docTitle =
    'Dashboard | Angle | Powering Subscriptions for Local Businesses';
  if (document.title !== docTitle) document.title = docTitle;

  return (
    <div className="p-8 bg-gray-50 w-full">
      <h3 className="mb-2 text-display-sm font-semibold text-gray-900">
        Welcome to Angle!
      </h3>
      <p className="mb-8 text-md font-regular text-gray-500">
        We help specialty coffee houses, fast-casual restaurants, and craft
        breweries create subscription experiences.
      </p>
      <div className="max-w-[1096px]">
        <div className="bg-white border border-gray-200 p-6 mb-6 w-full rounded-xl flex flex-row gap-x-10 items-center">
          <div className="ml-6 max-w-[332px]">
            <div className="relative mb-2 w-fit">
              <h3 className="text-display-xs font-semibold text-gray-900 whitespace-nowrap mr-1">
                Create your first subscription
              </h3>
              <Badge
                color="blue"
                text="Soon"
                className="absolute top-0 left-full"
              />
            </div>
            <p className="text-md font-regular text-gray-500">
              Create a retail or hybrid subscription in minutes using our simple
              step-by-step process.
            </p>
          </div>
          <div className="w-2/3">
            <img
              src="assets/images/create-subscription.png"
              alt="Create your first subscription"
              className="object-fit"
            />
          </div>
        </div>
        <div className="flex flex-row gap-x-6">
          <Checklist
            title="Launch checklist"
            items={items}
            className="w-1/2 bg-white p-6 border border-gray-200 rounded-xl"
          />
          <div className="w-1/2 bg-white p-6 border border-gray-200 rounded-xl flex flex-col">
            <div className="text-display-xs font-semibold text-gray-900 mb-4">
              Activity
            </div>
            <div className="flex flex-col items-center justify-center flex-1">
              <div className="w-14 h-14 bg-gray-100 border-[10px] border-gray-50 rounded-[28px] flex items-center justify-center mb-5">
                <Icon src="refresh" className="text-black w-7 h-7" />
              </div>
              <div className="text-xl font-medium text-gray-900 mb-2">
                No activity
              </div>
              <div className="text-md font-regular text-gray-500 text-center">
                When your first subscription is live, you’ll see activity like
                new subscribers, orders, subscription upgrades and more.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
