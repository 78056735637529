import { FC } from 'react';
import { ReactComponent as BarChartSquare } from '../../../assets/icons/bar-chart-square.svg';
import { ReactComponent as Users } from '../../../assets/icons/users.svg';
import { ReactComponent as ReceiptCheck } from '../../../assets/icons/receipt-check.svg';
import { ReactComponent as PieChart } from '../../../assets/icons/pie-chart.svg';
import { ReactComponent as Repeat } from '../../../assets/icons/repeat.svg';
import { ReactComponent as PlusCircle } from '../../../assets/icons/plus-circle.svg';
import { ReactComponent as List } from '../../../assets/icons/list.svg';
import { ReactComponent as Browser } from '../../../assets/icons/browser.svg';
import { ReactComponent as Wallet } from '../../../assets/icons/wallet.svg';
import { ReactComponent as PuzzlePiece } from '../../../assets/icons/puzzle-piece.svg';
import { ReactComponent as Settings } from '../../../assets/icons/settings.svg';
import { ReactComponent as ArrowNarrowRight } from '../../../assets/icons/arrow-narrow-right.svg';
import { ReactComponent as SquareLogo } from '../../../assets/icons/square-logo.svg';
import { ReactComponent as Square } from '../../../assets/icons/square.svg';
import { ReactComponent as CheckSquareBroken } from '../../../assets/icons/check-square-broken.svg';
import { ReactComponent as Refresh } from '../../../assets/icons/refresh.svg';
import { ReactComponent as Check } from '../../../assets/icons/check.svg';
import { ReactComponent as Disconnection } from '../../../assets/icons/disconnection.svg';
import { ReactComponent as AlertOctagon } from '../../../assets/icons/alert-octagon.svg';
import { ReactComponent as Logout } from '../../../assets/icons/log-out.svg';
import { ReactComponent as HelpCircle } from '../../../assets/icons/help-circle.svg';
import { ReactComponent as Info } from '../../../assets/icons/info.svg';
import { ReactComponent as ArrowBigRight } from '../../../assets/icons/arrow-big-right.svg';
import { ReactComponent as ArrowTop } from '../../../assets/icons/arrow-top.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg';

export const iconMap = {
  barChartSquare: BarChartSquare,
  users: Users,
  receiptCheck: ReceiptCheck,
  pieChart: PieChart,
  repeat: Repeat,
  plusCircle: PlusCircle,
  list: List,
  browser: Browser,
  wallet: Wallet,
  puzzlePiece: PuzzlePiece,
  settings: Settings,
  arrowNarrowRight: ArrowNarrowRight,
  squareLogo: SquareLogo,
  square: Square,
  checkSquareBroken: CheckSquareBroken,
  refresh: Refresh,
  check: Check,
  disconnection: Disconnection,
  alertOctagon: AlertOctagon,
  logout: Logout,
  helpCircle: HelpCircle,
  info: Info,
  arrowBigRight: ArrowBigRight,
  arrowTop: ArrowTop,
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
};

export type IconSrc = keyof typeof iconMap;
type Props = { src: IconSrc; className?: string };

export const Icon: FC<Props> = ({ src, className }) => {
  const Component = iconMap[src];
  return <Component className={className} />;
};
