import { createEffect } from 'effector';
import { API_URL } from '../../config';

export const connectSquareFx = createEffect(async (organizationId: string) => {
  const res = await fetch(`${API_URL}/square-oauth/${organizationId}/link`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  const json = await res.json();

  if (!res.ok) {
    throw new Error(json.message);
  }

  const data: { squareOAuthLink: string } = json;

  window.open(data.squareOAuthLink, '_blank');
});

export const getSquareConnectionStatusFx = createEffect(
  async (organizationId: string) => {
    const res = await fetch(
      `${API_URL}/square-oauth/${organizationId}/status`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const json = await res.json();

    if (!res.ok) {
      throw new Error(json.message);
    }

    const data: { connected: boolean } = json;

    return data;
  }
);

export const disconnectSquareFx = createEffect(
  async (organizationId: string) => {
    const res = await fetch(
      `${API_URL}/square-oauth/${organizationId}/disconnect`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    if (!res.ok) {
      const json = await res.json();
      throw new Error(json.message);
    }
  }
);
