import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthLayout, MenuLayout } from '../../components';
import { DashboardPage } from '../DashboardPage';
import { MembersPage } from '../MembersPage';
import { OrdersPage } from '../OrdersPage';
import { ProfilePage } from '../ProfilePage';
import { AnalyticsPage } from '../AnalyticsPage';
import { SubscriptionPlansPage } from '../SubscriptionPlansPage';
import { CatalogPage } from '../CatalogPage';
import { LandingPagesPage } from '../LandingPagesPage';
import { WalletPassesPage } from '../WalletPassesPage';
import { IntegrationsPage } from '../IntegrationsPage';
import { SettingsLayout, Profile, Password } from '../AccountSettingsPage';
import { LoginPage } from '../LoginPage';
import { SignupPage } from '../SignupPage';
import { ComponentsPage } from '../ComponentsPage';
import { PrivatePage, PublicPage } from './components';
import {
  LOGIN_ROUTE,
  MAIN_ROUTE,
  DASHBOARD_ROUTE,
  MEMBERS_ROUTE,
  ORDERS_ROUTE,
  PROFILE_ROUTE,
  ANALYTICS_ROUTE,
  SUBSCRIPTION_PLANS_ROUTE,
  CATALOG_ROUTE,
  LANDING_PAGES_ROUTE,
  WALLET_PASSES_ROUTE,
  INTEGRATIONS_ROUTE,
  SETTINGS_PROFILE_ROUTE,
  SETTINGS_PASSWORD_ROUTE,
  SIGNUP_ROUTE,
  COMPONENTS_ROUTE,
} from './constants';
import { StripeConnect } from '../../components';

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivatePage />}>
          <Route element={<AuthLayout />}>
            <Route path={PROFILE_ROUTE} element={<ProfilePage />} />
          </Route>
          <Route element={<MenuLayout />}>
            {[MAIN_ROUTE, DASHBOARD_ROUTE].map((path, index) => (
              <Route path={path} element={<DashboardPage />} key={index} />
            ))}
            <Route path={MEMBERS_ROUTE} element={<MembersPage />} />
            <Route path={ORDERS_ROUTE} element={<OrdersPage />} />
            <Route path={ANALYTICS_ROUTE} element={<AnalyticsPage />} />
            <Route
              path={SUBSCRIPTION_PLANS_ROUTE}
              element={<SubscriptionPlansPage />}
            />
            <Route path={CATALOG_ROUTE} element={<CatalogPage />} />
            <Route path={LANDING_PAGES_ROUTE} element={<LandingPagesPage />} />
            <Route path={WALLET_PASSES_ROUTE} element={<WalletPassesPage />} />
            <Route path={INTEGRATIONS_ROUTE} element={<IntegrationsPage />} />
            <Route element={<SettingsLayout />}>
              <Route path={SETTINGS_PROFILE_ROUTE} element={<Profile />} />
              <Route path={SETTINGS_PASSWORD_ROUTE} element={<Password />} />
              {/* Not needed for 1.0 version */}
              {/* <Route path={SETTINGS_PAYMENTS_ROUTE} element={<Payments />} /> */}
            </Route>
          </Route>
        </Route>
        <Route element={<PublicPage />}>
          <Route element={<AuthLayout />}>
            <Route path={LOGIN_ROUTE} element={<LoginPage />} />
            <Route path={SIGNUP_ROUTE} element={<SignupPage />} />
          </Route>
        </Route>
        <Route path={COMPONENTS_ROUTE} element={<ComponentsPage />} />
        <Route path={'/stripe'} element={<StripeConnect />} />
        <Route path="*" element={<p>Not Found</p>} />
      </Routes>
    </BrowserRouter>
  );
};
