import { createEvent, createEffect, createStore } from 'effector';
import {
  IGetOrdersByOrgResponse,
  IGetOrdersByOrgRequest,
} from '@angle/shared/api-types/orders';

import { API_URL, DEFAULT_PAGE_SIZE } from '../../config';

type SortingDirection = 'asc' | 'desc';

export type IOrdersPages = {
  organizationId: string;
  pageSize: number;
  pageNum: number;
  sortingColumn: string;
  sortingDir: SortingDirection;
};

type SortingParam = {
  column: string;
  dir: SortingDirection;
};

export const evts = {
  loadPage: createEvent<IGetOrdersByOrgResponse>(),
  setOrganizationId: createEvent<string>(),
  setPageSize: createEvent<number>(),
  setPageNumber: createEvent<number>(),
  setParams: createEvent<IOrdersPages>(),
  setSortingParam: createEvent<SortingParam>(),
  setSortingColumn: createEvent<string>(),
  setSortingDirection: createEvent<SortingDirection>(),
};

const InitialOrdersPage: IOrdersPages = {
  organizationId: '',
  pageSize: DEFAULT_PAGE_SIZE,
  pageNum: 0,
  sortingColumn: 'createdAt',
  sortingDir: 'desc',
};

export const getOrgOrdersFx = createEffect(
  async ({
    organizationId,
    pageSize,
    pageNum,
    sortingColumn,
    sortingDir,
  }: IGetOrdersByOrgRequest) => {
    const orderBy = (sortingDir === 'desc' ? '-' : '') + sortingColumn;
    const res = await fetch(
      `${API_URL}/orders/org/${organizationId}?pageSize=${pageSize}&pageNum=${pageNum}&orderBy=${orderBy}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    const json = await res.json();

    if (!res.ok) {
      throw new Error(json.message);
    }

    return json;
  }
);

export const $orders = {
  params: createStore<IOrdersPages>(InitialOrdersPage),
  customers: createStore<IGetOrdersByOrgResponse>({
    orders: [],
    pageNum: 0,
    hasNext: false,
    hasPrev: false,
    count: 0,
  }),
  loading: getOrgOrdersFx.pending,
};

$orders.params.watch((val) => {
  console.log(`Params changed: ${JSON.stringify(val, null, 2)}`);
});

$orders.params.on(evts.setPageSize, (state, size) => {
  return { ...state, pageSize: size };
});

$orders.params.on(evts.setOrganizationId, (state, id) => {
  return { ...state, organizationId: id };
});

$orders.params.on(evts.setPageNumber, (state, num) => {
  return { ...state, pageNum: num };
});

$orders.params.on(evts.setParams, (state, newState) => {
  return { ...state, ...newState };
});

$orders.params.on(evts.setSortingColumn, (state, column) => {
  return { ...state, pageNum: 0, sortingColumn: column };
});

$orders.params.on(evts.setSortingDirection, (state, dir) => {
  return { ...state, pageNum: 0, sortingDir: dir };
});

$orders.params.on(evts.setSortingParam, (state, { column, dir }) => {
  return { ...state, pageNum: 0, sortingColumn: column, sortingDir: dir };
});

$orders.params.watch(
  ({ organizationId, pageSize, pageNum, sortingColumn, sortingDir }) => {
    if (organizationId !== '') {
      getOrgOrdersFx({
        organizationId,
        pageSize,
        pageNum,
        sortingColumn,
        sortingDir,
      });
    }
  }
);

$orders.customers.on(getOrgOrdersFx.doneData, (_, newState) => newState);
