import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SETTINGS_PROFILE_ROUTE } from '../../pages/Router/constants';
import { Icon } from '..';

type Props = {
  firstName: string;
  lastName: string;
  job: string;
};

export const Avatar: FC<Props> = ({ firstName, lastName, job }) => {
  const firstLetters = firstName
    ?.substring(0, 1)
    ?.concat(lastName?.substring(0, 1))
    ?.toUpperCase();

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <div className="w-[32px] h-[32px] rounded-full bg-primary-100 flex justify-center items-center text-primary-600">
          {firstLetters}
        </div>
        <div className="ml-2.5">
          <div className="leading-5 text-gray-700 font-medium">
            {`${firstName ? firstName : ''} ${lastName ? lastName : ''}`}
          </div>
          <div className="text-sm text-gray-500 font-regular leading-4">
            {job}
          </div>
        </div>
      </div>
      <Link to={SETTINGS_PROFILE_ROUTE}>
        <Icon src="settings"></Icon>
      </Link>
    </div>
  );
};
