import cn from 'classnames';
import { ReactNode, FC } from 'react';

export type BadgeColor = 'blue' | 'light-blue' | 'green' | 'red' | 'yellow';
export type BadgeSize = 'small' | 'medium';

type Props = {
  color?: BadgeColor;
  size?: BadgeSize;
  text: string;
  className?: string;
  children?: ReactNode;
};

export const Badge: FC<Props> = ({
  color = 'blue',
  size = 'small',
  text,
  className,
  children,
}) => {
  return (
    <div
      className={cn(
        className,
        'w-fit rounded leading-3 font-medium flex items-center h-fit',
        {
          'bg-primary-600 text-white': color === 'blue',
          'bg-primary-50 text-primary-700': color === 'light-blue',
          'bg-success-50 text-success-700': color === 'green',
          'bg-error-50 text-error-700': color === 'red',
          'bg-warning-50 text-warning-700': color === 'yellow',
          'text-xs py-1.5 px-3 rounded-2xl': size === 'small',
          'text-sm py-2 px-4 rounded-2xl': size === 'medium',
        }
      )}
    >
      {children}
      <div>{text}</div>
    </div>
  );
};
