import { useStore } from 'effector-react';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Button, Input } from '../../components';
import { $authError, logInFx } from '../../feature/auth';
import { SIGNUP_ROUTE } from '../Router/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema } from '../../validation';
import { LoginRequestType } from '@angle/shared/api-types/users';

export function LoginPage() {
  const isLoading = useStore(logInFx.pending);
  const error = useStore($authError);
  const { register, handleSubmit, formState } = useForm<LoginRequestType>({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = (data: LoginRequestType) => {
    logInFx(data);
  };

  const docTitle =
    'Log In | Angle | Powering Subscriptions for Local Businesses';
  if (document.title !== docTitle) document.title = docTitle;

  return (
    <>
      <div className="mb-8">
        <h1 className="mb-3 font-semibold text-gray-900 text-display-sm text-left">
          Log in
        </h1>
        <p className="text-gray-500 text-md font-regular text-left">
          Welcome back!
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* TODO: Add alert component */}
        {error && <p>{error}</p>}
        <div className="grid gap-5">
          <Input
            {...register('email')}
            type="email"
            labelText="Email"
            isError={!!formState.errors.email}
            messageText={formState.errors.email?.message}
            focus={true}
          />
          <Input
            {...register('password')}
            type="password"
            labelText="Password"
            isError={!!formState.errors.password}
            messageText={formState.errors.password?.message}
          />
        </div>
        <div className="mt-6">
          <Button size="lg" isLoading={isLoading} type="submit" fullWidth>
            Sign in
          </Button>
        </div>
        <div className="mt-8">
          <p className="flex items-center justify-center gap-1 text-base font-regular text-gray-500 font-regular">
            Don’t have an account?
            <Link to={SIGNUP_ROUTE} className="font-medium text-primary-700">
              Sign up
            </Link>
          </p>
        </div>
      </form>
    </>
  );
}
