import { useState, useEffect, FC, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { Button, Icon, Badge } from '../../components';
import {
  connectSquareFx,
  getSquareConnectionStatusFx,
  disconnectSquareFx,
} from '../../feature/square';
import { getUserDataFx } from '../../feature/profile';

type ModalProps = {
  position?: 'center' | 'right';
  children?: ReactNode;
};

const Modal: FC<ModalProps> = ({ position = 'center', children }) => {
  return (
    <div className="fixed top-0 left-0 h-screen w-screen backdrop-blur bg-gray-700/70">
      <div
        className={cn(
          'absolute bg-white w-2/5 min-w-[350px] flex flex-col justify-between',
          {
            'inset-1/2 translate-x-[-50%] translate-y-[-50%] h-fit rounded-xl p-6':
              position === 'center',
            'right-0 h-full': position === 'right',
          }
        )}
      >
        {children}
      </div>
    </div>
  );
};

export function IntegrationsPage() {
  const [isLearnMoreOpen, setLearnMoreOpen] = useState(false);
  const [isDisconnectOpen, setDisconnectOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [organizationId, setOrganizationId] = useState('');

  const squareError = searchParams?.get('error');
  const squareErrorDescription = searchParams.get('error_description');

  useEffect(() => {
    getUserDataFx()
      .then((userData) => {
        setOrganizationId(userData.organization.id);
        return userData.organization.id;
      })
      .then((organizationId) => getSquareConnectionStatusFx(organizationId))
      .then((data) => {
        setIsConnected(data.connected);
        setIsLoading(false);
      });
  }, [searchParams]);

  const getConnectingLinkFx = async () => {
    setIsConnecting(true);
    await connectSquareFx(organizationId);
    setIsConnecting(false);
  };

  const disconnectFx = async () => {
    setIsDisconnecting(true);
    await disconnectSquareFx(organizationId);
    setIsDisconnecting(false);
    setIsConnected(false);
    setDisconnectOpen(false);
    setSearchParams(undefined);
  };

  const docTitle =
    'Integrations | Angle | Powering Subscriptions for Local Businesses';
  if (document.title !== docTitle) document.title = docTitle;

  return (
    <div className="p-8 bg-gray-50 w-full">
      {isLearnMoreOpen && (
        <Modal position="right">
          <div>
            <div className="flex flex-col justify-center">
              <img
                src="assets/images/square-integration.png"
                alt="Square Integration"
                className="object-cover max-h-[240px]"
              />
            </div>
            <div className="p-6">
              {!isConnected ? (
                <h3 className="text-2xl">Get started with Square</h3>
              ) : (
                <div className="flex">
                  <h3 className="text-2xl mr-2">Square integration</h3>
                  <Badge color="green" size="medium" text="Connected">
                    <Icon src="check" className="text-success-500" />
                  </Badge>
                </div>
              )}
              {!isConnected ? (
                <p className="mt-6 text-base font-regular text-gray-500">
                  Connect Square to import your catalog, link products to your
                  subscription benefits and route subscriber orders directly to
                  your POS. You will need a Square account in order to create
                  this integration.{' '}
                </p>
              ) : (
                <>
                  <p className="mt-6 text-base font-regular text-gray-500">
                    You've successfully connected to Square. Your catalog items
                    has been synchronized with Angle.
                  </p>
                  <p className="mt-6 text-base font-regular text-gray-500">
                    You can disconnect and stop syncing data from Square to
                    Angle using button below.
                  </p>
                </>
              )}
              {!isConnected && (
                <>
                  <p className="mt-6 text-lg">Setup process:</p>
                  <ol className="mt-6 pl-6 list-decimal text-base font-regular leading-8 text-gray-500">
                    <li>Click the connect button to get started.</li>
                    <li>Sign in to your Square account.</li>
                    <li>Grant access to your Square data.</li>
                    <li>Angle will automatically import your catalog.</li>
                    <li>
                      Once your subscription is live, orders will flow directly
                      to your POS.
                    </li>
                  </ol>
                </>
              )}
            </div>
          </div>
          <div className="p-6 border flex justify-between">
            <Button
              onClick={() => setLearnMoreOpen(false)}
              color="secondary"
              className="border-gray-300"
            >
              Cancel
            </Button>
            {isLoading ? (
              <Button size="md" isLoading={true}>
                Connect
              </Button>
            ) : isConnected ? (
              <Button
                className="mr-4"
                color="dangerLight"
                isLoading={isDisconnecting}
                disabled={!isConnected}
                onClick={() => {
                  setLearnMoreOpen(false);
                  setDisconnectOpen(true);
                }}
              >
                Disconnect from Square
              </Button>
            ) : (
              <Button
                size="md"
                isLoading={isConnecting}
                disabled={isConnected}
                onClick={() => getConnectingLinkFx()}
              >
                Connect
              </Button>
            )}
          </div>
        </Modal>
      )}
      {isDisconnectOpen && (
        <Modal>
          <Icon src="disconnection" className="mb-4" />
          <h3 className="text-lg mr-1">Do you really want to disconnect?</h3>
          <p className="mt-4 text-sm font-regular text-gray-500">
            This will stop syncs from running and remove the application at
            Square.
          </p>
          <p className="mt-2 text-sm font-regular text-gray-500">
            Clicking the disconnect button will immediately disconnect the
            integration and log you out.{' '}
          </p>
          <div className="mt-8 flex justify-between">
            <Button
              onClick={() => setDisconnectOpen(false)}
              color="secondary"
              className="border-gray-300 w-2/4 mr-3"
            >
              Cancel
            </Button>
            <Button
              className="w-2/4"
              color="danger"
              isLoading={isDisconnecting}
              disabled={!isConnected}
              onClick={() => disconnectFx()}
            >
              Disconnect
            </Button>
          </div>
        </Modal>
      )}
      {squareError && (
        <Modal>
          <Icon src="alertOctagon" className="mb-4" />
          <h3 className="text-lg mr-1">{squareErrorDescription}</h3>
          <p className="mt-4 text-sm font-regular text-gray-500">
            Something went wrong and connection has been failed. Please try
            again and make sure you click “Allow” on the permission pop-up.
          </p>
          <div className="my-4 py-2 px-4 bg-error-50 border border-error-100 rounded-md">
            <span className="text-error-400 font-regular">{`Error code: ${squareError}`}</span>
          </div>
          <div className="mt-8 flex justify-between">
            <Button
              color="secondary"
              className="border-gray-300 w-2/4 mr-3"
              onClick={() => setSearchParams(undefined)}
            >
              Cancel
            </Button>
            <Button
              className="w-2/4"
              isLoading={isDisconnecting}
              disabled={isConnected}
              onClick={() => getConnectingLinkFx()}
            >
              Try again
            </Button>
          </div>
        </Modal>
      )}
      <h3 className="mb-2 text-3xl">Integrations</h3>
      <p className="text-base font-regular text-gray-500">
        Connect Angle to your POS and e-commerce platform for a seamless
        subscription experience.
      </p>
      <div className="grid grid-cols-2 gap-6 mt-8">
        <div className="border bg-white rounded-md p-6">
          <div className="flex justify-between items-center">
            <Icon src="squareLogo" />
            {isLoading ? (
              <Button size="md" isLoading={true}>
                Connect
              </Button>
            ) : isConnected ? (
              <Badge color="green" size="medium" text="Connected">
                <Icon src="check" className="text-success-500" />
              </Badge>
            ) : (
              <Button
                size="md"
                isLoading={isConnecting}
                disabled={isConnected}
                onClick={() => getConnectingLinkFx()}
              >
                Connect
              </Button>
            )}
          </div>
          <h4 className="text-2xl mt-4 mb-2">Square</h4>
          <p className="text-sm text-gray-500 font-regular">
            Connect Square to import your catalog, link products to your
            subscriptions and route orders directly to your POS.
          </p>
          <Button
            className="mt-2"
            variant="link"
            color="link"
            onClick={() => setLearnMoreOpen(true)}
          >
            Learn more
            <Icon src="arrowNarrowRight" className="ml-2.5" />
          </Button>
        </div>
      </div>
    </div>
  );
}
