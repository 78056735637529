import { FC } from 'react';
import { useStore } from 'effector-react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';
import {
  MAIN_ROUTE,
  DASHBOARD_ROUTE,
  MEMBERS_ROUTE,
  ORDERS_ROUTE,
  ANALYTICS_ROUTE,
  SUBSCRIPTION_PLANS_ROUTE,
  CATALOG_ROUTE,
  LANDING_PAGES_ROUTE,
  WALLET_PASSES_ROUTE,
  INTEGRATIONS_ROUTE,
} from '../../pages/Router/constants';
import { $userData } from '../../feature/profile';

import { SeparatorText } from '../ui/SeparatorText';
import { Avatar } from '../ui/Avatar';
import logoIcon from '../../../assets/icons/logo.svg';
import { Icon } from '..';

export const Sidebar: FC = () => {
  const userData = useStore($userData);

  const navLinkClasses = 'flex items-center p-2 mb-1';
  const activeNavLinkClasses = ' bg-primary-50 text-primary-700 rounded-md';
  const allNavLinkClasses = (isActive: boolean) =>
    navLinkClasses + (isActive && activeNavLinkClasses);

  const path = useLocation().pathname;

  const isDashboardActive = path === '/' || path === '/dashboard';

  return (
    <div className="flex flex-col justify-between min-w-[280px] max-h-screen sticky top-0 border-r pt-8 pr-4 pb-6 pl-6">
      <div className="flex flex-col">
        <Link to={MAIN_ROUTE} className="pb-6">
          <img width={70} height={32} src={logoIcon} alt="Angle Logo" />
        </Link>
        <div className="flex flex-col text-sm text-gray-700">
          <NavLink
            to={DASHBOARD_ROUTE}
            className={cn(navLinkClasses, {
              [`${activeNavLinkClasses}`]: isDashboardActive,
            })}
          >
            {({ isActive }) => (
              <>
                <div className="flex w-[30px]">
                  <Icon
                    src="barChartSquare"
                    className={cn(
                      { 'text-gray-500': !isActive },
                      { 'text-primary-500': isActive }
                    )}
                  />
                </div>
                Dashboard
              </>
            )}
          </NavLink>
          <NavLink
            to={MEMBERS_ROUTE}
            className={({ isActive }) => allNavLinkClasses(isActive)}
          >
            {({ isActive }) => (
              <>
                <div className="flex w-[30px]">
                  <Icon
                    src="users"
                    className={cn(
                      'mr-2',
                      { 'text-gray-500': !isActive },
                      { 'text-primary-500': isActive }
                    )}
                  />
                </div>
                Members
              </>
            )}
          </NavLink>
          <NavLink
            to={ORDERS_ROUTE}
            className={({ isActive }) => allNavLinkClasses(isActive)}
          >
            {({ isActive }) => (
              <>
                <div className="flex w-[30px]">
                  <Icon
                    src="receiptCheck"
                    className={cn(
                      'mr-2',
                      { 'text-gray-500': !isActive },
                      { 'text-primary-500': isActive }
                    )}
                  />
                </div>
                Orders
              </>
            )}
          </NavLink>
          <NavLink
            to={ANALYTICS_ROUTE}
            className={({ isActive }) => allNavLinkClasses(isActive)}
          >
            {({ isActive }) => (
              <>
                <div className="flex w-[30px]">
                  <Icon
                    src="pieChart"
                    className={cn(
                      'mr-2',
                      { 'text-gray-500': !isActive },
                      { 'text-primary-500': isActive }
                    )}
                  />
                </div>
                Analytics
              </>
            )}
          </NavLink>
          <SeparatorText text="Experiences" isShorter />
          <NavLink
            to={SUBSCRIPTION_PLANS_ROUTE}
            className={({ isActive }) => allNavLinkClasses(isActive)}
          >
            {({ isActive }) => (
              <div className="flex justify-between w-full">
                <div className="flex">
                  <div className="flex w-[30px]">
                    <Icon
                      src="repeat"
                      className={cn(
                        'mr-2',
                        { 'text-gray-500': !isActive },
                        { 'text-primary-500': isActive }
                      )}
                    />
                  </div>
                  Subscription Plans
                </div>
                <Icon
                  src="plusCircle"
                  className={cn(
                    'mr-2',
                    { 'text-gray-500': !isActive },
                    { 'text-primary-500': isActive }
                  )}
                />
              </div>
            )}
          </NavLink>
          <SeparatorText text="Content" isShorter />
          <NavLink
            to={CATALOG_ROUTE}
            className={({ isActive }) => allNavLinkClasses(isActive)}
          >
            {({ isActive }) => (
              <>
                <div className="flex w-[30px]">
                  <Icon
                    src="list"
                    className={cn(
                      'mr-2',
                      { 'text-gray-500': !isActive },
                      { 'text-primary-500': isActive }
                    )}
                  />
                </div>
                Catalog
              </>
            )}
          </NavLink>
          <NavLink
            to={LANDING_PAGES_ROUTE}
            className={({ isActive }) => allNavLinkClasses(isActive)}
          >
            {({ isActive }) => (
              <>
                <div className="flex w-[30px]">
                  <Icon
                    src="browser"
                    className={cn(
                      'mr-2',
                      { 'text-gray-500': !isActive },
                      { 'text-primary-500': isActive }
                    )}
                  />
                </div>
                Landing Pages
              </>
            )}
          </NavLink>
          <NavLink
            to={WALLET_PASSES_ROUTE}
            className={({ isActive }) => allNavLinkClasses(isActive)}
          >
            {({ isActive }) => (
              <>
                <div className="flex w-[30px]">
                  <Icon
                    src="wallet"
                    className={cn(
                      'mr-2',
                      { 'text-gray-500': !isActive },
                      { 'text-primary-500': isActive }
                    )}
                  />
                </div>
                Wallet Passes
              </>
            )}
          </NavLink>
          <SeparatorText text="Content" isShorter />
          <NavLink
            to={INTEGRATIONS_ROUTE}
            className={({ isActive }) => allNavLinkClasses(isActive)}
          >
            {({ isActive }) => (
              <>
                <div className="flex w-[30px]">
                  <Icon
                    src="puzzlePiece"
                    className={cn(
                      'mr-2',
                      { 'text-gray-500': !isActive },
                      { 'text-primary-500': isActive }
                    )}
                  />
                </div>
                Integrations
              </>
            )}
          </NavLink>
        </div>
      </div>

      <Avatar
        firstName={userData.firstName}
        lastName={userData.lastName}
        job={userData.name}
      />
    </div>
  );
};
