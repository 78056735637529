import { FC } from 'react';
import cn from 'classnames';

type Props = {
  text?: string;
  isShorter?: boolean;
};

export const SeparatorText: FC<Props> = ({ text, isShorter }) => {
  return (
    <div className={cn('my-4', { 'pr-[12px]': isShorter })}>
      <div className="text-gray-500 text-xs border-b w-full leading-[0.1rem]">
        {text && <span className="bg-white pr-[12px]">{text}</span>}
      </div>
    </div>
  );
};
