import { Badge } from '../../components';

export function SubscriptionPlansPage() {
  const docTitle =
    'Subscription Plans | Angle | Powering Subscriptions for Local Businesses';
  if (document.title !== docTitle) document.title = docTitle;

  return (
    <div className="p-8 bg-gray-50 w-full">
      <div className="relative mb-2 w-fit">
        <h3 className="text-4xl mr-1">Subscription Plans</h3>
        <Badge color="blue" text="Soon" className="absolute top-0 left-full" />
      </div>
      <p className="mb-8 text-sm text-gray-500">
        Create a retail or hybrid subscription in minutes using our simple
        step-by-step process.{' '}
      </p>
      <img
        src="assets/images/subscription-plans.png"
        alt="Subscription Plans"
        className="object-cover max-h-[734px]"
      />
    </div>
  );
}
