import { createEffect, createEvent, createStore, forward } from 'effector';
import {
  LoginRequestType,
  SignUpRequestType,
} from '@angle/shared/api-types/users';
import { API_URL } from '../../config';
import { getUserDataFx } from '../profile';

export const USER_SESSION_COOKIE_NAME = 'angle.user.session';

const getIsLoggedIn = () =>
  document.cookie.indexOf(`${USER_SESSION_COOKIE_NAME}=`) !== -1;

export const setIsLoggedIn = createEvent();

export const $isLoggedIn = createStore(getIsLoggedIn()).on(
  setIsLoggedIn,
  () => {
    return getIsLoggedIn();
  }
);

export const logInFx = createEffect(
  async ({ email, password }: LoginRequestType) => {
    // TODO: setup axios
    const res = await fetch(`${API_URL}/users/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    if (res.status !== 200) {
      const json = await res.json();
      throw new Error(json.message);
    } else {
      await getUserDataFx();
    }
  }
);

export const signUpFx = createEffect(
  async ({ email, password }: SignUpRequestType) => {
    const res = await fetch(`${API_URL}/users/sign-up`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    if (res.status !== 200) {
      const json = await res.json();
      throw new Error(json.message);
    } else {
      await getUserDataFx();
    }
  }
);

export const logoutFx = createEffect(async () => {
  const res = await fetch(`${API_URL}/users/logout`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (res.status !== 200) {
    const json = await res.json();
    throw new Error(json.message);
  }
});

export const $authError = createStore('')
  .on(
    [logInFx.failData, signUpFx.failData, logoutFx.failData],
    (_, payload) => payload.message
  )
  .on([logInFx.pending, signUpFx.pending, logoutFx.pending], () => '');

forward({
  from: [logInFx.done, signUpFx.done, logoutFx.done],
  to: setIsLoggedIn,
});
