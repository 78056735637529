import { useStore } from 'effector-react';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IChangeUserPasswordRequest } from '@angle/shared/api-types/users';
import { changePasswordSchema } from '../../validation';
import { Alert, Button, Input } from '../../components';
import { passwordChangeFx, $changePasswordError } from '../../feature/profile';

type ChangePasswordSchema = IChangeUserPasswordRequest & {
  confirmNewPassword: string;
};

export const Password: FC = () => {
  const { register, handleSubmit, formState } = useForm<ChangePasswordSchema>({
    resolver: yupResolver(changePasswordSchema),
  });

  const errorTexts = {
    notStrongEnough: "Password doesn't meet our strength requirements",
    currentDoesNotMatch: "Current password doesn't match",
  };
  const error = useStore($changePasswordError);
  const notStrongEnoughError = error === errorTexts.notStrongEnough;
  const currentDoesNotMatchError = error === errorTexts.currentDoesNotMatch;

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data: IChangeUserPasswordRequest) => {
    setIsLoading(true);
    passwordChangeFx(data)
      .then(() => {
        setSuccess(true);
        setIsLoading(false);
      })
      .catch(() => {
        setSuccess(false);
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-[480px]">
      <div className="grid gap-5">
        {success && <Alert title="Your password successfully changed" />}
        <Input
          {...register('currentPassword')}
          type="password"
          labelText="Current password"
          isError={
            !!formState.errors.currentPassword || !!currentDoesNotMatchError
          }
          messageText={
            currentDoesNotMatchError
              ? errorTexts.currentDoesNotMatch
              : formState.errors.currentPassword?.message
          }
          isMandatory
        />
        <Input
          {...register('newPassword')}
          type="password"
          labelText="New password"
          isError={!!formState.errors.newPassword || !!notStrongEnoughError}
          messageText={
            notStrongEnoughError
              ? errorTexts.notStrongEnough
              : formState.errors.newPassword?.message
          }
          isMandatory
        />
        <Input
          {...register('confirmNewPassword')}
          type="password"
          labelText="Confirm new password"
          isError={
            !!formState.errors.confirmNewPassword || !!notStrongEnoughError
          }
          messageText={
            notStrongEnoughError
              ? errorTexts.notStrongEnough
              : formState.errors.confirmNewPassword?.message
          }
          isMandatory
        />

        <Button
          size="lg"
          type="submit"
          className="mt-8"
          isLoading={isLoading}
          onClick={() => setSuccess(false)}
        >
          Save Changes
        </Button>
      </div>
    </form>
  );
};
