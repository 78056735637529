import { FC } from 'react';
import cn from 'classnames';

import { Icon } from './Icon';
import { Badge } from './';

type Item = {
  id: string;
  text: string;
  done: boolean;
};

type Props = {
  title: string;
  className?: string;
  items: Item[];
};

export const Checklist: FC<Props> = ({ title, className, items }) => {
  return (
    <div className={className}>
      <div className="text-display-xs font-semibold text-gray-900 mb-6">
        {title}
      </div>

      <ul className="flex flex-col">
        {items.map((item, idx) => (
          <li
            id={item.id}
            key={item.id}
            className={cn('flex flex-row gap-x-3', {
              'pb-4': idx === 0,
              'border-t border-gray-200 py-4':
                idx !== 0 && idx !== items.length - 1,
              'border-t border-gray-200 pt-4': idx === items.length - 1,
            })}
          >
            {item.done ? (
              <Icon
                src="checkSquareBroken"
                className="text-primary-500 w-6 h-6"
              />
            ) : (
              <Icon
                src="square"
                className={cn('text-gray-500 w-6 h-6', {
                  'text-primary-500': items[idx - 1].done,
                })}
              />
            )}

            <div className="flex flex-row gap-x-1 justify-between items-center w-full">
              <div
                className={cn('flex font-medium text-md', {
                  'line-through  text-gray-500': item.done,
                  'text-primary-700': !item.done && items[idx - 1].done,
                  'text-gray-600': !item.done && !items[idx - 1].done,
                })}
              >
                {item.text}
                {!item.done && items[idx - 1].done && (
                  <Badge color="blue" text="Soon" className="ml-2" />
                )}
              </div>

              {!item.done && items[idx - 1].done && (
                <Icon src="arrowNarrowRight" className="text-primary-600" />
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
