import classNames from 'classnames';
import React, {
  ReactNode,
  InputHTMLAttributes,
  useMemo,
  useState,
} from 'react';
import { nanoid } from 'nanoid';

import eyeIcon from '../../../assets/icons/eye.svg';
import eyeOffIcon from '../../../assets/icons/eye-off.svg';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  labelText?: string;
  isError?: boolean;
  isMandatory?: boolean;
  messageText?: string;
  placeholder?: string;
  containerStyle?: string;
  website?: boolean;
  elementsRight?: ReactNode;
  focus?: boolean;
}

// TODO: add password type, max characters, loading state, input icon and textarea support if needed
export const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    type = 'text',
    labelText,
    isError = false,
    isMandatory = false,
    messageText,
    placeholder = undefined,
    containerStyle,
    website = false,
    elementsRight = null,
    focus = false,
    ...rest
  } = props;
  const uniqueId = useMemo(() => nanoid(), []);

  const [inputType, setInputType] = useState<string>(type);

  const changeInputType = () => {
    setInputType((prevType) => (prevType === 'text' ? 'password' : 'text'));
  };

  const inputProps = {
    id: uniqueId,
    type: inputType,
    ref,
    ...rest,
  };
  return (
    <div className={containerStyle}>
      {labelText && (
        <div className="flex items-center mb-1.5">
          <label
            htmlFor={uniqueId}
            className="text-sm font-medium text-gray-700"
          >
            {labelText}
            {isMandatory && (
              <span className="text-s text-error-600 ml-0.5">*</span>
            )}
          </label>
        </div>
      )}
      <div className="relative">
        <input
          {...inputProps}
          type={inputType}
          autoComplete={type}
          placeholder={placeholder}
          className={classNames(
            'w-full placeholder:text-gray-500 border border-solid border-gray-300 rounded-lg inline-flex items-center justify-center px-3 py-2.5 h-11 text-gray-900 font-regular text-md',
            'focus:border-primary-300 focus:shadow-xs-primary outline-none',
            'disabled:bg-gray-50 disabled:pointer-events-none',
            {
              'border-error-300 focus:shadow-xs-error focus:border-error-300':
                isError && !inputProps.disabled,
              'pl-[88px]': website,
            }
          )}
          autoFocus={focus}
        />
        {type === 'password' && (
          <div className="absolute top-0 flex items-center h-10 right-4">
            <button
              className="focus:outline-none "
              type="button"
              onClick={changeInputType}
              title={
                inputType === 'password' ? 'Show password' : 'Hide password'
              }
              aria-label={
                inputType === 'password' ? 'Show password' : 'Hide password'
              }
            >
              {inputType === 'password' ? (
                <img src={eyeIcon} alt="show password" />
              ) : (
                <img src={eyeOffIcon} alt="hide password" />
              )}
            </button>
          </div>
        )}

        {messageText && (
          <p
            className={classNames('text-sm font-regular text-gray-500 mt-1.5', {
              'text-error-500': isError && !inputProps.disabled,
            })}
          >
            {messageText}
          </p>
        )}

        {website && (
          <div className="absolute top-0 flex items-center h-11 left px-3 py-2.5 text-gray-500 font-regular border-r">
            https://
          </div>
        )}

        {elementsRight && (
          <div className="absolute top-0 flex items-center h-11 right-4">
            {elementsRight}
          </div>
        )}
      </div>
    </div>
  );
});
