import { useStore } from 'effector-react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { $isLoggedIn } from '../../../feature/auth';
import { getUserDataFx, $userData } from '../../../feature/profile';
import { LOGIN_ROUTE, PROFILE_ROUTE } from '../constants';
import { useEffect } from 'react';

export const PrivatePage = () => {
  const isLoggedIn = useStore($isLoggedIn);
  const userData = useStore($userData);
  const location = useLocation();

  useEffect(() => {
    getUserDataFx();
  }, []);

  if (!isLoggedIn) {
    return <Navigate to={LOGIN_ROUTE} state={{ from: location }} replace />;
  }

  if (
    userData.isInitialized &&
    !userData.firstName &&
    location.pathname !== PROFILE_ROUTE
  ) {
    return <Navigate to={PROFILE_ROUTE} state={{ from: location }} replace />;
  }

  return <Outlet />;
};
