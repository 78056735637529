import { PropsWithChildren } from 'react';
import cn from 'classnames';
import { Icon } from './Icon';

type StrElement = string | JSX.Element;

export interface TableColumn<T> {
  title: string;
  value: (row: T) => StrElement;
  sorting?: string;
}

type TableColumnValueFn<T> = (obj: T) => string | JSX.Element;

export function Column<T>(
  title: string,
  value: TableColumnValueFn<T>,
  sorting?: string
): TableColumn<T> {
  return { title, value, sorting };
}

type HandlerPage = (i: number) => void;

type HandlerSorting = (column: string, dir: 'asc' | 'desc') => void;

interface Props<T> {
  columns: TableColumn<T>[];
  rows: T[];
  wrapperStyles?: string;
  tableStyles?: string;
  loading?: boolean;
  sortingColumn?: string;
  sortingDir?: string;
  pagesNum: number;
  currPage: number;
  hasNext?: boolean;
  hasPrev?: boolean;
  onClickPage: HandlerPage;
  onChangeOrder?: HandlerSorting;
}

interface PageLinkProps {
  page: number;
  isCurrent: boolean;
  onClick: HandlerPage;
}

const FoldLink = (
  <div className="inline-block text-gray-500 py-2 px-4 mx-2">...</div>
);
const PageLink = ({ page, isCurrent, onClick }: PageLinkProps) => {
  const pageNumberStyles = 'inline-block text-gray-500 py-2 px-4 mx-2';
  const pageActiveNumberStyles = 'text-gray-800 bg-primary-100 rounded-lg';

  if (isCurrent) {
    return (
      <div key={page} className={cn(pageNumberStyles, pageActiveNumberStyles)}>
        {page + 1}
      </div>
    );
  } else {
    return (
      <button
        key={page}
        className={pageNumberStyles}
        onClick={() => onClick(page)}
      >
        {page + 1}
      </button>
    );
  }
};

export const pagination = (
  num: number,
  curr: number,
  onClick: HandlerPage
): JSX.Element[] => {
  const pgSize = 7;
  const allPages = Array.from(Array(num).keys()).map((i) => {
    return <PageLink page={i} isCurrent={i === curr} onClick={onClick} />;
  });
  let pages: JSX.Element[];

  if (num <= 7) {
    pages = [...allPages];
  } else {
    const lft = Math.max(curr - 1, 0);
    const rgt = Math.min(curr + 1, num) + 1;
    const foldL = lft > 2;
    const foldR = num - rgt > 2;

    pages = [
      ...(lft > 0 ? [allPages[0]] : []),
      ...(lft === 2 ? [allPages[1]] : []),
      ...(foldL ? [FoldLink] : []),
      ...allPages.slice(
        foldR ? lft : Math.min(lft, num - pgSize + 2),
        foldL ? rgt : Math.max(rgt, pgSize - 2)
      ),
      ...(foldR ? [FoldLink] : []),
      ...(num - rgt === 2 ? [allPages[num - 2]] : []),
      ...(rgt < num ? [allPages[num - 1]] : []),
    ];
  }

  return pages;
};

export function Table<T>({
  columns,
  rows,
  wrapperStyles,
  tableStyles,
  loading,
  sortingColumn,
  sortingDir,
  pagesNum,
  currPage,
  hasNext,
  hasPrev,
  onClickPage,
  onChangeOrder,
}: PropsWithChildren<Props<T>>) {
  const cellStyles = 'p-4 flex items-center border-b';

  const $header = columns.map(({ title, sorting }, idx) => {
    if (sorting !== undefined) {
      const nextDir =
        sorting !== sortingColumn || sortingDir === 'desc' ? 'asc' : 'desc';
      return (
        <div
          key={idx}
          onClick={() => onChangeOrder?.(sorting, nextDir)}
          className={cn(cellStyles, 'text-xs text-gray-500 cursor-pointer')}
        >
          {title}
          {sortingColumn === sorting && (
            <Icon
              src={sortingDir === 'asc' ? 'arrowTop' : 'arrowDown'}
              className="ml-1"
            />
          )}
        </div>
      );
    } else {
      return (
        <div key={idx} className={cn(cellStyles, 'text-xs text-gray-500')}>
          {title}
        </div>
      );
    }
  });

  const $rows = rows.map((row) => {
    const $cells = columns.map(({ value }, colIdx) => {
      return (
        <div
          key={colIdx}
          className={cn(cellStyles, 'items-center text-gray-900 font-regular')}
        >
          <div className="truncate">{value(row)}</div>
        </div>
      );
    });

    return $cells;
  });

  const $pages = <div>{pagination(pagesNum, currPage, onClickPage)}</div>;

  const $prev =
    hasPrev !== undefined ? (
      <button
        onClick={hasPrev ? () => onClickPage(currPage - 1) : void 0}
        className={cn('flex items-center', {
          'text-gray-500': hasPrev,
          'text-gray-300': !hasPrev,
        })}
      >
        <Icon src="arrowLeft" className="mr-2" />
        Previous
      </button>
    ) : null;

  const $next =
    hasNext !== undefined ? (
      <button
        onClick={hasNext ? () => onClickPage(currPage + 1) : void 0}
        className={cn('flex items-center', {
          'text-gray-500': hasNext,
          'text-gray-300': !hasNext,
        })}
      >
        Next
        <Icon src="arrowRight" className="ml-2" />
      </button>
    ) : null;

  return (
    <div className={cn('flex flex-col justify-between', wrapperStyles)}>
      <div
        className={cn(
          `grid grid-cols-${columns.length} bg-white rounded-lg border`,
          tableStyles
        )}
      >
        {$header}
        {$rows}
      </div>
      <div className="mt-4 flex justify-between items-center">
        {$prev}
        {$pages}
        {$next}
      </div>
    </div>
  );
}
