import PropTypes from 'prop-types';

export interface ITooltip {
  content: string;
  children?: JSX.Element;
  disabled: boolean;
}

export const Tooltip = ({ content, disabled, children }: ITooltip) => {
  return (
    <div className="tooltipContainer">
      <div className={`tooltip${disabled ? ' disabled' : ''}`}>{content}</div>
      {children}
    </div>
  );
};

Tooltip.propTypes = {
  content: PropTypes.string.isRequired,
  tooltipRef: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
};
