import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { environment } from '@angle/business-owner-app/environment';

Sentry.init({
  dsn: environment.sentryDSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
  environment: environment.tag,
});
