import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Sidebar } from '..';

export const MenuLayout: FC = () => {
  return (
    <div className="flex min-h-screen">
      <Sidebar />
      <Outlet />
    </div>
  );
};
